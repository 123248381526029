<template>
  <div class="backGround">
    <!--  <div class="backgroundColorCalendar"></div> -->
    <!---------------------------EVENT MODAL-------------------------->
    <div class="modal" id="eventModal">
      <div class="modal-dialog">
        <div class="modal-content modalContentDark">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Event Info</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <table class="calendarEventTable table table-bordered">
              <tbody>
                <tr>
                  <th>Event name:</th>
                  <td>{{ this.clickedEvent.event_name }}</td>
                </tr>
                <tr>
                  <th>User:</th>
                  <td>
                    {{
                      this.eventUser.first_name + " " + this.eventUser.last_name
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pilots:</th>
                  <td
                    v-show="
                      this.clickedEventPilot !=
                      'DO NOT DELETE ME DO NOT DELETE ME'
                    "
                  >
                    {{ this.clickedEventPilot }}
                  </td>
                </tr>
                <tr>
                  <th>Drones:</th>
                  <td>{{ this.clickedEventDrone }}</td>
                </tr>
                <tr>
                  <th>Batteries:</th>
                  <td>{{ this.clickedEventBattery }}</td>
                </tr>
                <tr>
                  <th>Date Start:</th>
                  <td>
                    {{
                      new Date(this.clickedEvent.date_start).toLocaleString()
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Date End:</th>
                  <td>
                    {{ new Date(this.clickedEvent.date_end).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <th>Event description:</th>
                  <td>{{ this.clickedEvent.reason }}</td>
                </tr>

                <tr>
                  <th>Request Status:</th>
                  <td>{{ this.requestStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              v-show="
                currentUser.role == 'admin' ||
                currentUser.role == 'semi-admin' ||
                currentUser.role == 'developer'
              "
              type="button"
              class="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#eventEditModal"
              id="eventModalEdit"
              @click="jumpToEventEditModal()"
              v-if="new Date(this.clickedEvent.date_end).getTime() < Date.now()"
              disabled
            >
              Edit
            </button>
            <button
              v-show="
                currentUser.role == 'admin' ||
                currentUser.role == 'semi-admin' ||
                currentUser.role == 'developer'
              "
              type="button"
              class="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#eventEditModal"
              id="eventModalEdit"
              @click="jumpToEventEditModal()"
              v-else
            >
              Edit
            </button>

            <button
              v-show="
                currentUser.role == 'admin' ||
                currentUser.role == 'semi-admin' ||
                currentUser.role == 'developer'
              "
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="eventModalDelete"
              @click="eventModalDelete(this.clickedEvent.event_id)"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="eventModalClose"
            >
              Close
            </button>
          </div>
          <br />
        </div>
      </div>
    </div>
    <!-- ----------------------EVENT EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="eventEditModal"
      role="dialog"
      aria-labelledby="eventEditModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editTitle">&nbsp; Event edit:</h5>
            <h5 class="modal-title" id="eventEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <table class="calendarEventTable table table-bordered">
              <tbody v-if="this.clickedEvent">
                <tr>
                  <th>Event name:</th>
                  <td>
                    <input
                      id="eventEditEventName"
                      type="text"
                      :value="this.clickedEvent.event_name"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Date Start:</th>
                  <td>
                    <input
                      type="date"
                      name="startDatePicker"
                      id="startDatePicker"
                      class="event-input-basket form-control"
                      style="width: 100%"
                      v-model="this.eventStart"
                      @change="selectEventEditDate()"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>Time Start:</th>
                  <td>
                    <input
                      type="time"
                      name="startTimePicker"
                      id="startTimePicker"
                      class="event-input-basket form-control"
                      style="width: 100%"
                      v-model="this.eventStartTime"
                      @change="selectEventEditDate()"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Date End:</th>
                  <td>
                    <input
                      type="date"
                      name="endDatePicker"
                      id="endDatePicker"
                      class="event-input-basket form-control"
                      style="width: 100%"
                      v-model="this.eventEnd"
                      @change="selectEventEditDate()"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Time End:</th>
                  <td>
                    <input
                      type="time"
                      name="endTimePicker"
                      id="endTimePicker"
                      class="event-input-basket form-control"
                      style="width: 100%"
                      v-model="this.eventEndTime"
                      @change="selectEventEditDate()"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Event description:</th>
                  <td>
                    <textarea
                      name="Text1"
                      cols="40"
                      rows="5"
                      id="eventEditReason"
                      :value="this.clickedEvent.reason"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <th>User:</th>
                  <td>
                    {{
                      this.eventUser.first_name + " " + this.eventUser.last_name
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Drones:</th>
                  <td>
                    <!-- <input
                      v-for="(item, index) of this.eventEditDronesArray"
                      :key="item"
                      type="text"
                      :value="item"
                      :id="'editEventDrones' + index"
                      @change="selectDrone()"

                    /> -->
                    <input
                      v-for="(item, index) of this.eventEditDronesArray"
                      :key="item.drone_lab_id"
                      list="avaliableDroneArray"
                      name="serarchavaliableDroneArray"
                      :id="'editEventDrones' + index"
                      class="techDropdown"
                      autocomplete="off"
                      v-model="this.selectedDrone[index]"
                      @change="selectDrone(index)"
                    />
                    <datalist id="avaliableDroneArray">
                      <option
                        v-for="item in this.avaliableDroneArray"
                        :key="item.lab_id"
                        :value="item.lab_id"
                      ></option>
                    </datalist>
                    <button @click="addEditRow('drone')">+</button>
                    <button @click="removeEditRow('drone')">-</button>
                  </td>
                </tr>
                <tr
                  v-for="(item, index) of this.eventEditDronesArray"
                  :key="item.drone_lab_id"
                >
                  <th>Pilots for: {{ item.drone_lab_id }}</th>
                  <td
                    v-show="
                      this.clickedEventPilot !=
                      'DO NOT DELETE ME DO NOT DELETE ME'
                    "
                  >
                    <!-- <input
                      v-for="(item, pilotindex) of this.eventEditDronesArray[
                        index
                      ].pilot_id"
                      :key="item"
                      type="text"
                      :value="item"
                      :id="'editEventPilots' + index + pilotindex"
                    /> -->
                    <input
                      v-for="(item, pilotindex) of this.eventEditDronesArray[
                        index
                      ].pilot_id"
                      :key="item"
                      list="avaliablePilotArray"
                      name="serarchavaliableDroneArray"
                      :id="'editEventPilots' + index + pilotindex"
                      class="techDropdown"
                      autocomplete="off"
                      v-model="
                        this.selectedPilotArray[index][pilotindex].licence_id
                      "
                      @change="selectPilotEventEdit(index, pilotindex)"
                    />
                    <datalist id="avaliablePilotArray">
                      <option
                        v-for="item in this.availablePilotsEventEdit"
                        :key="item.licence_id"
                        :value="item.licence_id"
                      ></option>
                    </datalist>
                    <button @click="addEditRow('pilot', index)">+</button>
                    <button @click="removeEditRow('pilot', index)">-</button>
                  </td>
                </tr>

                <tr
                  v-for="(item, index) of this.eventEditDronesArray"
                  :key="item.drone_lab_id"
                >
                  <th>Batteries for: {{ item.drone_lab_id }}</th>
                  <td>
                    <input
                      v-for="(item, batteryIndex) of this
                        .eventEditBatteriesArray[index]"
                      :key="item"
                      list="avaliableBatteryArray"
                      name="serarchavaliableBatteryArray"
                      :id="'editEventBatteries' + index + batteryIndex"
                      class="techDropdown"
                      autocomplete="off"
                      v-model="this.selectedBattery[index][batteryIndex]"
                      @change="selectBatteryEventEdit(index, batteryIndex)"
                    />
                    <!-- 
                      :value="item.battery_lab_id"
                      
                      
                      <input
                    v-for="(item, batteryIndex) of this
                        .eventEditBatteriesArray[index]"
                      :key="item"
                      list="avaliableBatteryArray"
                      name="serarchavaliableDroneArray"
                      :id="'editEventBatteries' + index + batteryIndex"
                      class="techDropdown"
                      autocomplete="off"
                      v-model="this.selectedBattery[index]"
                      @change="selectBatteryEventEdit(batteryIndex)"
                    />-->
                    <datalist id="avaliableBatteryArray">
                      <option
                        v-for="item in this.availableBatteryArrayEventEdit"
                        :key="item.lab_id"
                        :value="item.lab_id"
                      ></option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <th>Request Status:</th>
                  <td>{{ this.requestStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <div class="footerButtons">
              <button
                class="btn btn-secondary"
                type="button"
                id="sureButtonEventEdit"
                @click="acceptEventEdit()"
              >
                Accept
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-danger"
                id="cancelButtonEventEdit"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------USER EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="userEditModal"
      role="dialog"
      aria-labelledby="userEditModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editTitle">&nbsp; User edit:</h5>
            <h5 class="modal-title" id="userEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                Please fill the form if you want to edit the current User
                <br />
                <br />
                <label for="email">Email:&nbsp;</label>
                <input
                  v-model="editEmail"
                  type="text"
                  class="new-user-input form-control"
                  name="email"
                  id="emailInput"
                  disabled="disabled"
                  :placeholder="this.currentUser.email"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="firstName">First Name:&nbsp;</label>
                <input
                  v-model="editFirstName"
                  type="text"
                  name="firstName"
                  id="firstNameInput"
                  class="new-user-input form-control"
                  :placeholder="this.currentUser.first_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="lastName">Last Name:&nbsp;</label>
                <input
                  v-model="editLastName"
                  type="text"
                  name="lastName"
                  id="lastNameInput"
                  class="new-user-input form-control"
                  :placeholder="this.currentUser.last_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password"
                  >Password:&nbsp;
                  <a
                    href="#"
                    title="Password must contain: 
  -at least 8 characters 
  -at least 1 number
  -at least 1 lowercase character
  -at least 1 uppercase character"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-content="Popover text"
                    ><i class="fa-solid fa-circle-info"></i
                  ></a>
                </label>
                <input
                  v-model="editPassword"
                  type="password"
                  name="password"
                  id="passwordInput"
                  class="new-user-input form-control"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <button
                  @click="showPassword('password')"
                  class="eyeButtonCalendar"
                  id="showPasswordButton"
                >
                  <i class="fa-solid fa-eye" id="pwEye"></i>
                  <i class="far fa-eye-slash" id="pwSlashedEye"></i>
                </button>
                <br />
                <br />
                <label for="password">Password again:&nbsp;</label>
                <input
                  v-model="editPasswordAgain"
                  type="password"
                  name="passwordAgain"
                  id="passwordAgainInput"
                  class="new-user-input form-control"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <button
                  @click="showPassword('passwordAgain')"
                  class="eyeButtonCalendar"
                  id="showPasswordAgainButton"
                >
                  <i class="fa-solid fa-eye" id="pwAgainEye"></i>
                  <i class="far fa-eye-slash" id="pwAgainSlashedEye"></i>
                </button>
                <br />
                <br />

                <label for="phoneNum">Phone Number:&nbsp;</label>
                <input
                  v-model="editPhoneNum"
                  type="text"
                  name="phoneNum"
                  id="phoneNumInput"
                  class="new-user-input form-control"
                  :placeholder="this.phoneNumPlaceholder"
                  @keydown="isInputEnter()"
                  maxlength="7"
                />
                <input
                  list="selected"
                  name="userSearch"
                  id="editPhoneNumSelectInput"
                  class="new-user-input form-control"
                  autocomplete="off"
                  :placeholder="this.phoneselectedPlaceholder"
                  maxlength="2"
                  v-model="this.selectedNum"
                />

                <datalist id="selected">
                  <option>20</option>
                  <option>30</option>
                  <option>31</option>
                  <option>50</option>
                  <option>70</option>
                  <option>96</option>
                </datalist>
                <input
                  class="new-user-input form-control"
                  type="text"
                  id="plusPhone"
                  :value="this.plusPhone"
                  disabled
                />&nbsp;

                <br />
                <br />

                <label for="type">Organization:&nbsp;</label>&nbsp;

                <select
                  v-model="this.selectedOrganization"
                  class="techDropdown"
                  id="organizationInput1"
                >
                  <option hidden value="undefined">
                    {{ this.currentUser.organization }}
                  </option>
                  <option>DDC</option>
                  <option>JKK</option>
                  <option>AHJK</option>
                  <option>AK</option>
                  <option>EEKK</option>
                  <option>ESK</option>
                  <option>GIVK</option>
                  <option>KGK</option>
                  <option>MEK</option>
                  <option>MUK</option>
                  <option>Budapesti központ</option>
                  <option>ZalaZONE</option>
                  <option>None above</option>
                </select>
                <br v-show="this.selectedOrganization == 'None above'" />
                <br v-show="this.selectedOrganization == 'None above'" />
                <input
                  v-show="this.selectedOrganization == 'None above'"
                  v-model="editOrganization"
                  type="text"
                  name="organization"
                  id="organizationInput"
                  class="new-user-input form-control"
                  placeholder="Organization"
                  @keydown="isCreateEnter()"
                />
                <br />
                <br />
                <label for="role">Role:&nbsp;</label>
                <input
                  type="text"
                  class="new-user-input form-control"
                  name="role"
                  id="roleInput"
                  disabled="disabled"
                  :placeholder="this.currentUser.role"
                />
                <br />
                <br />
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    id="sureButtonUserEdit"
                    @click="userEdit(item, index)"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonUserEdit"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------BUG REPORT MODAL-------------------------------- -->
    <div
      class="modal fade"
      id="bugRepModal"
      role="dialog"
      aria-labelledby="bugRepModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Bug report:</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <label for="bugRepComment"
              >Please describe the error phenomenon exactly:</label
            >
            <textarea
              v-model="bugRepCommentVmod"
              class="form-control"
              rows="5"
              id="bugRepComment"
              name="text"
            ></textarea>
            <br />
            <button type="submit" class="btn btn-primary" @click="sendBugRep()">
              Submit
            </button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="cancelButtonBugRep"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------USERS LIST MODAL-------------------------------- -->
    <div
      class="modal fade"
      id="usersModal"
      role="dialog"
      aria-labelledby="usersModal"
      aria-hidden="true"
    >
      <div class="large modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">&nbsp; Users:</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="allUsersList">
              <div class="container">
                <table
                  class="allUsers table table-dark table-striped"
                  id="allUsersTable"
                >
                  <thead>
                    <tr>
                      <th>First Name:</th>
                      <th>Last Name:</th>

                      <th>Email:</th>
                      <th>Phone Number:</th>
                      <th>Organization:</th>
                      <th>Role:</th>
                      <th>Delete user:</th>
                      <th>Edit user:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.userArray"
                      :key="item.userId"
                    >
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].first_name }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].last_name }}
                      </td>

                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].email }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].phone_num }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].organization }}
                      </td>

                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="roleButton"
                        >
                          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
                          {{ this.userArray[index].role }}
                          <i class="fa-solid fa-caret-down" id="uIcon"></i>
                          &nbsp;
                        </button>
                        <div class="dropdown-menu calendarPageDropdownMenu">
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('developer', item)"
                            >Developer
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('admin', item)"
                            >Admin
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('semiAdmin', item)"
                            >Semi-Admin
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('user', item)"
                            >User
                          </a>
                        </div>
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn"
                          id="deleteUser"
                          @click="deleteUser(item, index)"
                        >
                          Delete User
                        </button>
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-secondary"
                          id="editUser"
                          data-bs-toggle="modal"
                          data-bs-target="#anotherUserEditModal"
                          @click="selectUser(item)"
                          v-if="this.userArray[index].role != 'admin'"
                        >
                          Edit User
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="cancelButtonCreateCalendarPage"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------ANOTHER USER EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="anotherUserEditModal"
      role="dialog"
      aria-labelledby="anotherUserEditModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content anotherModal">
          <div class="modal-header">
            <h5 id="editTitle">&nbsp; User edit:</h5>
            <h5 class="modal-title" id="userEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                <label for="email">Email:&nbsp;</label>
                <input
                  v-model="editEmail"
                  type="text"
                  class="new-user-input form-control"
                  name="email"
                  id="anotherUserEmailInput"
                  disabled="disabled"
                  autocomplete="off"
                  :placeholder="this.selectedUser.email"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="firstName">First Name:&nbsp;</label>
                <input
                  v-model="editFirstName"
                  type="text"
                  name="firstName"
                  id="anotherUserFirstNameInput"
                  class="new-user-input form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.first_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="lastName">Last Name:&nbsp;</label>
                <input
                  v-model="editLastName"
                  type="text"
                  name="lastName"
                  id="anotherUserLastNameInput"
                  class="new-user-input form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.last_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password">Password:&nbsp;</label>
                <input
                  v-model="editPassword"
                  type="password"
                  name="password"
                  id="passwordInputAnother"
                  class="new-user-input form-control"
                  placeholder="Password"
                  autocomplete="off"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password">Password again:&nbsp;</label>
                <input
                  v-model="editPasswordAgain"
                  type="password"
                  name="passwordAgain"
                  id="passwordAgainInputAnother"
                  class="new-user-input form-control"
                  autocomplete="off"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <br />
                <br />

                <label for="phoneNum">Phone Number:&nbsp;</label>
                <input
                  v-model="editPhoneNum"
                  type="text"
                  name="phoneNum"
                  id="anotherUserPhoneNumInput"
                  class="new-user-input form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.phone_num"
                  @keydown="isInputEnter"
                  maxlength="8"
                />
                <select
                  v-model="this.selectedNum"
                  class="new-user-input form-control"
                  id="phoneTwo"
                >
                  &nbsp;
                  <option hidden value="undefined">
                    {{ phoneselectedPlaceholder }}
                  </option>
                  <option>20</option>
                  <option>30</option>
                  <option>31</option>
                  <option>50</option>
                  <option>70</option>
                  <option>96</option>
                </select>
                <input
                  class="new-user-input form-control"
                  type="text"
                  id="plusPhoneAnother"
                  :value="this.plusPhone"
                  disabled
                />&nbsp;

                <br />
                <br />

                <label for="type">Organization:&nbsp;</label>&nbsp;

                <select
                  v-model="this.selectedOrganization"
                  class="techDropdown"
                >
                  <option hidden value="undefined">
                    {{ this.selectedUser.organization }}
                  </option>
                  <option>DDC</option>
                  <option>JKK</option>
                  <option>AHJK</option>
                  <option>AK</option>
                  <option>EEKK</option>
                  <option>ESK</option>
                  <option>GIVK</option>
                  <option>KGK</option>
                  <option>MEK</option>
                  <option>MUK</option>
                  <option>Budapesti központ</option>
                  <option>ZalaZONE</option>
                  <option>None above</option>
                </select>
                <br v-show="this.selectedOrganization == 'None above'" />
                <br v-show="this.selectedOrganization == 'None above'" />
                <input
                  v-show="this.selectedOrganization == 'None above'"
                  v-model="editOrganization"
                  type="text"
                  name="organization"
                  id="organizationInputAnother"
                  class="new-user-input form-control"
                  placeholder="Organization"
                  @keydown="isCreateEnter()"
                />
                <br />
                <br />

                <label for="role">Role:&nbsp;</label>
                <input
                  type="text"
                  class="new-user-input form-control"
                  name="role"
                  id="roleInputAnother"
                  disabled="disabled"
                  :placeholder="this.selectedUser.role"
                />
                <br />
                <br />
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    id="sureButtonUserEdit"
                    data-bs-dismiss="modal"
                    @click="anotherUserEdit(item, index)"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonUserEdit"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ---------------------------Left NAV BAR-------------------------- -->
    <div class="leftNavBar">
      <!-- ---------------------------BEFOR AFTER CHECK MODAL------------------- -->
      <div class="modal" id="uploadBeforeAfterCheckModal">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Upload files</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              <table class="table table-bordered" id="myeventsUploadModalTable">
                <tbody>
                  <tr>
                    <td>Acknowledgement receipt</td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="fromDDCHandoverContract"
                        @click="fromDDCHandoverContract()"
                      >
                        Download
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="toDDCHandoverContract"
                        data-bs-toggle="modal"
                        data-bs-target="#fromDDCHandoverContractModal"
                      >
                        Upload
                      </button>
                    </td>
                    <td>
                      <i
                        v-if="!this.actualEvent.give_contract"
                        class="fa-solid fa-xmark redSymbol"
                      ></i>
                      <i v-else class="fa-solid fa-check greenSymbol"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Before flight Lab</td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="beforeFlightLab"
                        data-bs-toggle="modal"
                        data-bs-target="#beforeLabDroneChooseModal"
                        v-show="!this.actualEvent.lab_check"
                      >
                        Go to chechlist
                      </button>
                    </td>
                    <td>-</td>
                    <td>
                      <i
                        v-show="!this.actualEvent.lab_check"
                        class="fa-solid fa-xmark redSymbol"
                      ></i>
                      <i
                        v-show="this.actualEvent.lab_check"
                        class="fa-solid fa-check greenSymbol"
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Before flight Field</td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="beforeFlightField"
                        data-bs-toggle="modal"
                        data-bs-target="#beforeFieldDroneChooseModal"
                        v-show="
                          this.actualEvent.lab_check &&
                          !this.actualEvent.field_check
                        "
                      >
                        Go to chechlist
                      </button>
                    </td>
                    <td>-</td>
                    <td>
                      <i
                        v-show="!this.actualEvent.field_check"
                        class="fa-solid fa-xmark redSymbol"
                      ></i>
                      <i
                        v-show="this.actualEvent.field_check"
                        class="fa-solid fa-check greenSymbol"
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td>After flight</td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="afterUploadButton"
                        data-bs-toggle="modal"
                        data-bs-target="#afterUploadDroneChooseModal"
                        v-show="
                          this.actualEvent.field_check &&
                          !this.actualEvent.after_check
                        "
                      >
                        Go to upload modal
                      </button>
                    </td>
                    <td>-</td>
                    <td>
                      <i
                        v-show="!this.actualEvent.after_check"
                        class="fa-solid fa-xmark redSymbol"
                      ></i>
                      <i
                        v-show="this.actualEvent.after_check"
                        class="fa-solid fa-check greenSymbol"
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Acknowledgement receipt</td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="fromBottomDDCHandoverContract"
                        @click="toDDCHandoverContract()"
                      >
                        Download
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        type="button"
                        id="toBottomDDCHandoverContract"
                        data-bs-toggle="modal"
                        data-bs-target="#toDDCHandoverContractModal"
                      >
                        Upload
                      </button>
                    </td>
                    <td>
                      <i
                        v-if="!this.actualEvent.get_contract"
                        class="fa-solid fa-xmark redSymbol"
                      ></i>
                      <i v-else class="fa-solid fa-check greenSymbol"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <!---------------------------------- BEFORE FLY LAB MODAL------------------------------ -->
      <div class="modal" id="beforeLabDroneChooseModal">
        <div class="modal-dialog modal-lg modal80Large">
          <div class="modal-content modalContentDark">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Before flight lab</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              1. Felszállóhely és környezet
              <table
                class="beforeCheckTable table table-bordered"
                id="beforeCheckTableId"
              >
                <tbody>
                  <tr>
                    <td>Biztosítás</td>
                    <td>Érvényes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Engedélyek megléte, érvényessége</td>
                    <td>Ellenőrizve</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check2"
                          name="option2"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              2. Földi irányítóállomás (RPS)/RC-távirányító
              <table
                class="beforeCheckTable table table-bordered"
                id="beforeCheckTable2Id"
              >
                <tbody>
                  <tr>
                    <td><b>2.1 RPS Akkumlátora/tápellátása:</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Műszaki állapot (sérülés, törés)</td>
                    <td>Ép, sérülésmentes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check3"
                          name="option3"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Kábelek, csatlakozók (tiszták)</td>
                    <td>Ép, sérülésmentesek</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check4"
                          name="option4"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Feszültség (kijelzett)</td>
                    <td>Ellenőrizve, előírt szinten</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check5"
                          name="option5"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b>2.2 Kezelőszervek, kapcsolók:</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Főkapcsoló</td>
                    <td>Ellenőrizve, kikapcsolva</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check6"
                          name="option6"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Kapcsolók</td>
                    <td>Ellenőrizve, kikapcsolva</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check7"
                          name="option7"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Vezérlőkarok</td>
                    <td>Akadálymentesek, semleges helyzetben</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check8"
                          name="option8"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Akkumlátor csatlakoztatása</td>
                    <td>Ellenőrizve, polaritás megfelelő</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check9"
                          name="option9"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              3. Légi jármű (UA)
              <table class="beforeCheckTable table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <b
                        >3.1 Fedélzeti akkumlátor ellenőrzése,
                        csatlakoztatása:</b
                      >
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Műszaki állapot (sérülés, törés)</td>
                    <td>Ép, sérülésmentes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check10"
                          name="option10"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Kábelek, csatlakozók</td>
                    <td>Épek, sérülésmentesek</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check11"
                          name="option11"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Feszültség (kijelzett)</td>
                    <td>Ellenőrizve, előírt szinten</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check12"
                          name="option12"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Főkapcsoló</td>
                    <td>Ellenőrizve, kikapcsolva</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check13"
                          name="option13"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Akkumlátor csatlakoztatása</td>
                    <td>Ellenőrizve, polaritás megfelelő</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check14"
                          name="option14"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Akkumlátor rögzítettsége</td>
                    <td>Elmozdulásmentes, megfelelő</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check15"
                          name="option15"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b>3.2 UA szerkezeti elemei:</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Műszaki állapot (sérülés, törés)</td>
                    <td>Ép, sérülésmentes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check16"
                          name="option16"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Légcsavarok műszaki állapot</td>
                    <td>Megfelelő, rögzített</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check17"
                          name="option17"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Motorok műszaki állapot</td>
                    <td>Megfelelő, rögzített</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check18"
                          name="option18"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Motortartók</td>
                    <td>Ép, sérülésmentes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check19"
                          name="option19"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Leszállómű</td>
                    <td>Ép, sérülésmentes, rögzített</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check20"
                          name="option20"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Antenna</td>
                    <td>Sérülésmentes, rögzített</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check21"
                          name="option21"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Hasznos teher</td>
                    <td>Rögzített, működőképes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check22"
                          name="option22"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><b>3.3 Egyéb:</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Térbeli tájolás</td>
                    <td>Ellenőrizve</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check23"
                          name="option23"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
            </div>

            <!-- Modal footer -->
            <div class="modal-footer modalFooterCalendar">
              <button
                type="button"
                class="btn btn-success"
                @click="applyBeforeLab()"
                id="applyButtonCreateCalendarPage"
              >
                Apply
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                id="cancelButtonBeforeUploadLab"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!---------------------------------- BEFORE FLY FIELD MODAL------------------------------ -->
      <div class="modal" id="beforeFieldDroneChooseModal">
        <div class="modal-dialog modal-lg modal80Large">
          <div class="modal-content modalContentDark">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Before flight field</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              1. Felszállóhely és környezet
              <table class="beforeCheckTable table table-bordered">
                <tbody>
                  <tr>
                    <td>Műveleti terület</td>
                    <td>Repülési zóna jól látható</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check24"
                          name="option24"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Akadályok</td>
                    <td>Felmérve</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check25"
                          name="option25"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Felszállóhely</td>
                    <td>Kijelölve, felszállásra alkalmas</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check26"
                          name="option26"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Időjárási körülmények</td>
                    <td>A légi jármű korlátain belül</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check27"
                          name="option27"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              2. Indítás
              <table class="beforeCheckTable table table-bordered">
                <tbody>
                  <tr>
                    <td>Felszállóhely</td>
                    <td>Ellenőrizve, akadálymentes</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check28"
                          name="option28"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>UA akkumlátor hőmérséklete (min. 18+ fok)</td>
                    <td>Megfelelő</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check29"
                          name="option29"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Kábelek, csatlakozók állapota</td>
                    <td>Épek, sérülésmentesek</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check30"
                          name="option30"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>UA elhelyezése</td>
                    <td>Ellenőrizve, stabil</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check31"
                          name="option31"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Földi irányítóállomás (RPS)</td>
                    <td>Bekapcsolva</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check32"
                          name="option32"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>UA-Főkapcsoló</td>
                    <td>Bekapcsolva</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check33"
                          name="option33"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Üzemképesség (hang és fényjelzés)</td>
                    <td>Megerősítve</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check34"
                          name="option34"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Kommunikáció/telemetria</td>
                    <td>Ellenőrizve, kapcsolat stabil</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check35"
                          name="option35"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Kalibráció/GPS-jel erőssége</td>
                    <td>Ellenőrizve, megfelelő</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check36"
                          name="option36"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Kamerakép</td>
                    <td>Tiszta, folyamatos</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check37"
                          name="option37"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Hasznos teher vezérlése</td>
                    <td>akadálymentes, ellenőrizve</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check38"
                          name="option38"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Műveletbe be nem vont személyek elhelyezkedése</td>
                    <td>biztonságos</td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="check39"
                          name="option39"
                          value="something"
                        />
                        <label class="form-check-label"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
            </div>

            <!-- Modal footer -->
            <div class="modal-footer modalFooterCalendar">
              <button
                type="button"
                class="btn btn-success"
                @click="applyBeforeField()"
                id="applyButtonCreateCalendarPage"
              >
                Apply
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                id="cancelButtonBeforeUploadField"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!---------------------------------- AFTER FLY MODAL------------------------------ -->
      <div class="modal" id="afterUploadDroneChooseModal">
        <div class="modal-dialog modal-lg modal80Large">
          <div class="modal-content modalContentDark">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">After flight</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
              <table class="table table-bordered" id="afterFlightTable">
                <tbody>
                  <tr>
                    <td v-show="logUploadAfterDone">
                      Log files:&nbsp;&nbsp;
                      <i class="fa-solid fa-check greenSymbol"></i>&nbsp;
                      <a
                        href="#"
                        title="After flight, you must upload all of the log files that created during your reservation.

In case of DJI, you can extract the log files from the smartphone or smart controller that you used to fly.
Inside the application's folder there is a folder called 'FlightRecord' which contains all of the flight logs.

In case of ARDUPILOT, you can extract the log files from the FC. You must establish MAVLink connection between the FC and a PC.
Start the Mission Planner on the PC. Under the 'Data' tab find the 'DataFlash Logs' menu item. Under that click the 'Download 
DataFlash Log Via Mavlink' button, select the related logs and click 'Download Selected Logs'. 
This will save that log to your MissionPlanner/logs directory, in a folder named after the vehicle type, such as QUADCOPTER."
                        data-bs-trigger="hover"
                        data-bs-content="Popover text"
                        ><i class="fa-solid fa-circle-info"></i
                      ></a>
                    </td>
                    <td v-show="!logUploadAfterDone">
                      Log files:&nbsp;&nbsp;
                      <i class="fa-solid fa-x redSymbol"></i> &nbsp;
                      <a
                        href="#"
                        title="After flight, you must upload all of the log files that created during your reservation.

In case of DJI, you can extract the log files from the smartphone or smart controller that you used to fly.
Inside the application's folder there is a folder called 'FlightRecord' which contains all of the flight logs.

In case of ARDUPILOT, you can extract the log files from the FC. You must establish MAVLink connection between the FC and a PC.
Start the Mission Planner on the PC. Under the 'Data' tab find the 'DataFlash Logs' menu item. Under that click the 'Download 
DataFlash Log Via Mavlink' button, select the related logs and click 'Download Selected Logs'. 
This will save that log to your MissionPlanner/logs directory, in a folder named after the vehicle type, such as QUADCOPTER."
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-content="Popover text"
                        ><i class="fa-solid fa-circle-info"></i
                      ></a>
                    </td>
                    <td>
                      <input
                        type="file"
                        class="form-control"
                        id="logFliesUploadAfter"
                        @change="this.logUploadAfter"
                        multiple
                      />
                    </td>
                  </tr>
                  <tr>
                    <td v-show="this.imageUploadAfterDone">
                      Images: &nbsp;
                      <i class="fa-solid fa-check greenSymbol"></i>&nbsp;
                      <a
                        href="#"
                        title="After finishing the work with the drone you must upload six photos from the drone. 
Front, back, left, right, top and bottom. ONLY upload JPG or PNG images"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-content="Popover text"
                        ><i class="fa-solid fa-circle-info"></i
                      ></a>
                    </td>
                    <td v-show="!this.imageUploadAfterDone">
                      Images: &nbsp;
                      <i class="fa-solid fa-x redSymbol"></i>&nbsp;
                      <a
                        href="#"
                        title="After finishing the work with the drone you must upload six photos from the drone. 
Front, back, left, right, top and bottom. ONLY upload JPG or PNG images"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-content="Popover text"
                        ><i class="fa-solid fa-circle-info"></i
                      ></a>
                    </td>
                    <td>
                      <input
                        type="file"
                        class="form-control"
                        id="imageUploadAfterDone"
                        @change="this.imageUploadAfter"
                        multiple
                      />
                    </td>
                  </tr>
                  <tr>
                    <td v-show="this.pdfUploadAfterDone">
                      PDF-s: &nbsp;&nbsp;<i
                        class="fa-solid fa-check greenSymbol"
                      ></i>
                      &nbsp;<a
                        href="#"
                        title="Click to download the sample Word doc"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-content="Popover text"
                        @click="downloadDocxFiles()"
                        ><i class="fa-solid fa-circle-info"></i
                      ></a>
                    </td>
                    <td v-show="!this.pdfUploadAfterDone">
                      PDF-s: &nbsp;&nbsp;<i class="fa-solid fa-x redSymbol"></i
                      >&nbsp;
                      <a
                        href="#"
                        title="Click to download the sample Word doc"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-content="Popover text"
                        @click="downloadDocxFiles()"
                        ><i class="fa-solid fa-circle-info"></i
                      ></a>
                    </td>
                    <td>
                      <input
                        type="file"
                        class="form-control"
                        id="pdfUploadAfter"
                        @change="this.pdfUploadAfter"
                        multiple
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
            </div>

            <!-- Modal footer -->
            <div class="modal-footer modalFooterCalendar">
              <button
                type="button"
                class="btn btn-success"
                @click="applyAfterUpload()"
                id="applyButtonCreateCalendarPage"
              >
                Apply
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                id="cancelButtonAfterUpload"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="eventModalButton"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#eventModal"
      ></button>
      <!-- ------------------------------------MENU BUTTONS--------------------------------- -->

      <button
        data-toggle="tooltip"
        data-placement="right"
        title="List view"
        id="listToggleButton"
        class="btn btn-secondary"
        type="button"
        @click="listPage()"
      >
        <i class="fa-solid fa-list"></i>
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        id="basket-button"
        @click="basket"
        data-toggle="tooltip"
        data-placement="right"
        title="Go to rent page"
      >
        <i class="fa-solid fa-shopping-basket"></i>
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        id="userGuideButton"
        @click="openUserGuide()"
        data-toggle="tooltip"
        data-placement="right"
        title="User Guide"
      >
        <i class="fa-regular fa-lightbulb"></i>
      </button>
      <button
        data-toggle="tooltip"
        data-placement="right"
        title="User edit"
        id="userEditButton"
        class="btn btn-secondary"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#userEditModal"
      >
        <i class="fas fa-cogs"></i>
      </button>

      <div
        class="addNewPilotButtonDiv"
        v-show="
          currentUser.role == 'admin' ||
          currentUser.role == 'semiAdmin' ||
          currentUser.role == 'developer'
        "
      >
        <button
          data-toggle="tooltip"
          data-placement="right"
          title="Add new drone"
          id="addNewDroneButton"
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCreate"
        >
          <i class="fa-solid fa-jet-fighter"></i>
        </button>
        <button
          data-toggle="tooltip"
          data-placement="right"
          title="Add new battery"
          id="addNewBatteryButton"
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalBatteryCreate"
        >
          <i class="fa-solid fa-battery-full"></i>
        </button>
        <button
          data-toggle="tooltip"
          data-placement="right"
          title="Add new pilot"
          id="addNewPilotButton"
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalPilotCreate"
        >
          <i class="fa-solid fa-user-astronaut"></i>
        </button>
        <button
          data-toggle="tooltip"
          data-placement="right"
          title="Users"
          id="usersButton"
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#usersModal"
        >
          <i class="fa-solid fa-address-card"></i>
        </button>
      </div>
      <button
        data-toggle="tooltip"
        data-placement="right"
        title="Bug Report"
        id="bugRepButton"
        class="btn btn-secondary"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#bugRepModal"
      >
        <i class="fa-solid fa-bug"></i>
      </button>
      <button
        data-toggle="tooltip"
        data-placement="right"
        title="Logout"
        id="logoutButton2"
        class="btn btn-secondary"
        type="button"
        @click="logout()"
      >
        <i class="fas fa-sign-out-alt"></i>
      </button>

      <!-- ---------------------------------CREATE DRONE MODAL------------------------------------------- -->
      <div
        class="modal fade"
        id="exampleModalCreate"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal60Large" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="createDroneTitle">Create new drone</h2>
              <h5 class="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <table
                class="newDroneTable table table-borderless"
                id="newDroneTableId"
              >
                <tbody>
                  <tr>
                    <td>
                      <label for="name"
                        >Drone name:&nbsp;
                        <a
                          href="#"
                          title="Only 4-21 character"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        v-model="createDroneName"
                        type="text"
                        name="Name"
                        id="droneName"
                        placeholder="Name"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label for="type">Required Batteries:&nbsp;</label>
                      <input
                        v-model="createReqBatteries"
                        type="number"
                        min="0"
                        name="comment"
                        id="reqBatteries"
                        placeholder="Required Batteries (must be nuber)"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Drone type:&nbsp;</label>
                      <input
                        v-model="createDroneType"
                        type="text"
                        name="type"
                        id="droneType"
                        placeholder="Drone type"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label for="type">Localization:&nbsp;</label>
                      <input
                        v-model="createDroneLocal"
                        type="text"
                        name="comment"
                        id="droneLocal"
                        placeholder="Localization"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Serial number:&nbsp;</label>
                      <input
                        v-model="createDroneSerialNum"
                        type="text"
                        name="serialNumber"
                        id="droneSerialNum"
                        placeholder="Serial number"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label for="type">Comment:&nbsp;</label>
                      <input
                        v-model="createDroneComment"
                        type="text"
                        name="comment"
                        id="droneComment"
                        placeholder="Comment"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Manufacturer:&nbsp;</label>
                      <input
                        v-model="createDroneManufacturer"
                        type="text"
                        name="manufacturer"
                        id="droneManufacturer"
                        placeholder="Manufacturer"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label for="type">Bought:&nbsp;</label>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <select
                        v-model="this.createDroneBought"
                        id="createDroneBoughtId"
                        class="techDropdown"
                      >
                        <option value="undefined" hidden selected>
                          Bought
                        </option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Registration number:&nbsp;</label>
                      <input
                        v-model="createDroneRegNum"
                        type="text"
                        name="registrationNumber"
                        id="regNum"
                        placeholder="Registration number"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone profile picture:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload1"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Start of insurance:&nbsp;</label>
                      <input
                        v-model="createStartInsur"
                        type="date"
                        name="startInsurance"
                        id="startInsur"
                        placeholder="Start of insurance"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone back:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload2"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">End of insurance:&nbsp;</label>
                      <input
                        v-model="createEndInsur"
                        type="date"
                        name="endInsurance"
                        id="endInsur"
                        placeholder="End of insurance"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone left:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload3"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Insurance Number:&nbsp;</label>
                      <input
                        v-model="createDroneInsurNum"
                        type="text"
                        name="insuranceNumber"
                        id="droneInsurNum"
                        placeholder="Insurance Number"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone right:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload4"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">LAB ID:&nbsp;</label>
                      <input
                        v-model="createDroneLabId"
                        type="text"
                        name="comment"
                        id="droneLabId"
                        placeholder="LAB ID"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone top:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload5"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Battery Type:&nbsp;</label>
                      <input
                        v-model="createDroneBatteryType"
                        type="text"
                        name="comment"
                        id="droneBatteryType"
                        placeholder="Battery Type"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone bottom:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload6"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="type">Battery Connector:&nbsp;</label>
                      <input
                        v-model="createDroneBatteryConnector"
                        type="text"
                        name="comment"
                        id="droneBatteryConnector"
                        placeholder="Battery Connector"
                        class="new-drone-input form-control"
                        @keydown="isCreateEnter"
                      />
                    </td>
                    <td>
                      <label class="form-label" for="customFile"
                        >Drone front:
                        <a
                          href="#"
                          title="ONLY upload JPG or PNG images"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-content="Popover text"
                          ><i class="fa-solid fa-circle-info"></i></a
                      ></label>
                      <input
                        type="file"
                        class="new-drone-input form-control"
                        id="droneImageUpload7"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer modalFooterCalendar">
              <div class="footerButtons">
                <button
                  type="button"
                  id="sureButtonCreateCalendarPage"
                  class="btn btn-success"
                  @click="createNewDrone()"
                >
                  Create
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-danger"
                  id="cancelButtonCreateDrone"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ---------------------------------CREATE BATTERY MODAL------------------------------------------- -->
      <div
        class="modal fade"
        id="exampleModalBatteryCreate"
        role="dialog"
        aria-labelledby="exampleModalBatteryLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="createBatteryTitle">Create a new battery</h2>
              <h5 class="modal-title" id="exampleModalBatteryLabel"></h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="col-auto">
                  Please fill the form if you want to create a new Battery
                  <br />
                  <br />
                  <label for="type">Battery cellnumber:&nbsp;</label>
                  <input
                    v-model="createBatteryCellNum"
                    type="text"
                    class="new-battery-input form-control"
                    name="type"
                    id="batteryCellNum"
                    placeholder="Cellnumber"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type">Battery capacity:&nbsp;</label>
                  <input
                    v-model="createBatteryCapacity"
                    type="text"
                    class="new-battery-input form-control"
                    name="batteryCapacity"
                    id="batteryCapacity"
                    placeholder="Capacity"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type">Manufacturer:&nbsp;</label>
                  <input
                    v-model="createBatteryManufacturer"
                    type="text"
                    name="batteryManufacturer"
                    id="batteryManufacturer"
                    class="new-battery-input form-control"
                    placeholder="Manufacturer"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type">Type:&nbsp;</label>
                  <input
                    v-model="createBatteryType"
                    type="text"
                    name="batteryType"
                    id="batteryType"
                    class="new-battery-input form-control"
                    placeholder="Type"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="name">LAB ID:&nbsp;</label>
                  <input
                    v-model="createBatteryLabId"
                    type="text"
                    name="Id"
                    id="batteryLabId"
                    class="new-battery-input form-control"
                    placeholder="ID"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type">Connector:&nbsp;</label>
                  <input
                    v-model="createBatteryConnector"
                    type="text"
                    name="batteryConnector"
                    id="batteryConnector"
                    class="new-battery-input form-control"
                    placeholder="Connector"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type"
                    >Name:&nbsp;
                    <a
                      href="#"
                      title="Only 4-21 character"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-content="Popover text"
                      ><i class="fa-solid fa-circle-info"></i></a
                  ></label>
                  <input
                    v-model="createBatteryName"
                    type="text"
                    name="batteryName"
                    id="batteryName"
                    class="new-battery-input form-control"
                    placeholder="Name"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type">Technology:&nbsp;</label>

                  <select
                    v-model="this.createBatteryTechnology"
                    class="techDropdown"
                    id="batteryTechnology"
                  >
                    <option value="undefined" hidden selected>
                      Technology
                    </option>
                    <option>Li-Po</option>
                    <option>Li-Ion</option>
                  </select>
                  <br />
                  <br />

                  <label for="type">C Value:&nbsp;</label>
                  <input
                    v-model="createBatteryCValue"
                    type="text"
                    name="batteryCValue"
                    id="batteryCValue"
                    class="new-battery-input form-control"
                    placeholder="C Value"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />

                  <label for="type">Local:&nbsp;</label>
                  <input
                    v-model="createBatteryLocal"
                    type="text"
                    name="batteryLocal"
                    id="batteryLocal"
                    class="new-battery-input form-control"
                    placeholder="Local"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label class="form-label" for="customFile"
                    >Battery top:
                    <a
                      href="#"
                      title="ONLY upload JPG or PNG images"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-content="Popover text"
                      ><i class="fa-solid fa-circle-info"></i></a
                  ></label>
                  <input
                    type="file"
                    class="new-battery-input form-control"
                    id="batteryImageUpload1"
                  />
                  <!-- image lesz ide -->
                  <br />
                  <br />
                  <label class="form-label" for="customFile"
                    >Battery bottom:
                    <a
                      href="#"
                      title="ONLY upload JPG or PNG images"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-content="Popover text"
                      ><i class="fa-solid fa-circle-info"></i></a
                  ></label>
                  <input
                    type="file"
                    class="new-battery-input form-control"
                    id="batteryImageUpload2"
                  />
                  <!-- image lesz ide -->
                  <br />
                  <br />
                </div>
                <div class="modal-footer">
                  <div class="footerButtons">
                    <button
                      type="button"
                      id="sureButtonBatteryCreateCalendarPage"
                      class="btn btn-success"
                      @click="createNewBattery()"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      class="btn btn-danger"
                      id="cancelButtonBatteryCreate"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ---------------------------------CREATE PILOT MODAL------------------------------------------- -->
      <div
        class="modal fade"
        id="exampleModalPilotCreate"
        role="dialog"
        aria-labelledby="exampleModalPilotLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="createPilotTitle">
                <i class="fa-solid fa-user-astronaut"></i> Create a new pilot
              </h2>
              <h5 class="modal-title" id="exampleModalPilotLabel"></h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="col-auto">
                  Please fill the form if you want to create a new Pilot
                  <br />
                  <br />
                  <label for="type">Licence ID:&nbsp;</label>
                  <input
                    v-model="createLicenceId"
                    type="text"
                    class="new-pilot-input form-control"
                    name="type"
                    id="licenceId"
                    placeholder="Licence ID"
                    @keydown="isCreateEnter"
                  />
                  <br />
                  <br />
                  <label for="type">Licence Level:&nbsp;</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <select
                    v-model="this.createLicenceLevel"
                    class="techDropdown"
                    id="createLicenceLevelId"
                  >
                    <option value="undefined" hidden selected>
                      Licence Level
                    </option>
                    <option>A1/A3</option>
                    <option>A2</option>
                  </select>

                  <br />
                  <br />
                  <label for="userSearch">Match to user:</label>
                  <input
                    list="users"
                    name="userSearch"
                    id="userSearch"
                    class="techDropdown"
                    autocomplete="off"
                    v-model="this.pilotToUser"
                  />
                  <datalist id="users">
                    <option
                      v-for="item in this.userArray"
                      :key="item.email"
                      :value="item.email"
                    ></option>
                  </datalist>
                  <br />
                  <br />
                  <label class="form-label" for="customFile"
                    >Profile picture:
                    <a
                      href="#"
                      title="ONLY upload JPG or PNG images"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-content="Popover text"
                      ><i class="fa-solid fa-circle-info"></i></a
                  ></label>
                  <input
                    type="file"
                    class="new-pilot-input form-control"
                    id="pilotImageUpload"
                  />
                  <br />
                  <br />
                </div>
                <div class="modal-footer">
                  <div class="footerButtons">
                    <button
                      type="button"
                      id="sureButtonPilotCreateCalendarPage"
                      class="btn btn-success"
                      @click="createNewPilot()"
                    >
                      <i class="fa-solid fa-plus"></i> Create
                    </button>
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      class="btn btn-danger"
                      id="cancelButtonPilotCreateCalendarPage"
                    >
                      <i class="fa-solid fa-xmark"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------------- HANDOVER CONTRACT--------------------------- -->
    <div class="modal" id="fromDDCHandoverContractModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">From DDC</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            Upload the document:
            <input
              type="file"
              class="form-control"
              id="fromDDCHandoverContractUpload"
              multiple
            />
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              @click="uploadHandoverContract('give')"
            >
              Apply
            </button>

            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="closeHandoverModalgive"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------------- HANDOVER CONTRACT--------------------------- -->
    <div class="modal" id="toDDCHandoverContractModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">To DDC</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            Upload the document:
            <input
              type="file"
              class="form-control"
              id="toDDCHandoverContractUpload"
              multiple
            />
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              @click="uploadHandoverContract('get')"
            >
              Apply
            </button>

            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="closeHandoverModalget"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  ----------------SUCCESS PILOT BAATER DRONE------------------->

    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      id="successPilot"
      data-bs-target="#successPilotModal"
    ></button>

    <!-- The Modal -->
    <div class="modal" id="successPilotModal">
      <div class="modal-dialog modal-sm">
        <div class="modal-content modalContentDark">
          <!-- Modal Header -->
          <div class="modal-header">
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">Pilot has been created.</div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      id="successBattery"
      data-bs-target="#successBatteryModal"
    ></button>

    <!-- The Modal -->
    <div class="modal" id="successBatteryModal">
      <div class="modal-dialog modal-sm">
        <div class="modal-content modalContentDark">
          <!-- Modal Header -->
          <div class="modal-header">
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">Battery has been created.</div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      id="successDrone"
      data-bs-target="#successDroneModal"
    ></button>

    <!-- The Modal -->
    <div class="modal" id="successDroneModal">
      <div class="modal-dialog modal-sm">
        <div class="modal-content modalContentDark">
          <!-- Modal Header -->
          <div class="modal-header">
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">Drone has been created.</div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ---------------------REQUESTS MODAL---------------------- -->
    <div
      class="modal fade"
      id="requestsModal"
      role="dialog"
      aria-labelledby="requestsModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modalContentDark">
          <div class="modal-header">
            <h5>&nbsp;Request</h5>
            <h5 class="modal-title"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body droneRequestD">
            <div class="row justify-content-center">
              <div class="col-auto">
                Please fill the form if you want to accept the request!
                <br />
                <br />
                <div class="droneRequestDiv">
                  <table
                    class="requestsTable table table-bordered"
                    id="batteryRequests-table"
                    v-for="(batteries, index) of this.reservedDroneArray
                      .droneBatteries"
                    :key="batteries.lab_id"
                  >
                    <tbody>
                      <tr
                        v-for="(item, pilotIndex) of this.reservedDroneArray
                          .drones[index].pilot_id"
                        :key="item"
                      >
                        <td class="tdLeft">
                          Pick pilot for: {{ batteries.lab_id }}
                        </td>

                        <td v-if="item == 'neededYes'" class="tdRight">
                          <input
                            :list="'requiredPilots' + pilotIndex"
                            name="requiredPilotSearch"
                            :id="'requiredPilotSearch' + index + pilotIndex"
                            class="request-input form-control requiredPilotsClass"
                            style="background-color: #fff"
                            v-model="this.reqiredPilots[index][pilotIndex]"
                            autocomplete="off"
                            type="text"
                            @change="
                              pilotSelect(
                                pilotIndex,
                                this.reqiredPilots[index][pilotIndex],
                                index
                              )
                            "
                            v-if="
                              this.pilotArrayLength >=
                              this.reservedDroneArray.drones[index].pilot_id
                                .length
                            "
                          />
                          <datalist :id="'requiredPilots' + pilotIndex">
                            <option
                              v-for="item in this.pilotArray"
                              :key="item.licence_id"
                              :value="item.email"
                            ></option>
                          </datalist>

                          <input
                            :list="'requiredPilots' + pilotIndex"
                            name="requiredPilotSearch"
                            :id="'requiredPilotSearch' + index + pilotIndex"
                            class="request-input form-control requiredPilotSearchClass"
                            autocomplete="off"
                            type="text"
                            value="There isn't enough free Pilot for this assignment."
                            v-if="
                              this.pilotArrayLength <
                              this.reservedDroneArray.drones[index].pilot_id
                                .length
                            "
                            disabled
                          />
                        </td>
                        <td v-else>
                          <input
                            disabled
                            :id="'requiredPilotSearch' + index + pilotIndex"
                            class="request-input form-control requiredPilotSearchClass"
                            placeholder="Don't need pilot"
                            value="neededNo"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td class="tdLeft">Pick batteries for:</td>
                        <td class="tdRight">{{ batteries.lab_id }}</td>
                      </tr>
                      <tr
                        v-for="(batteries, batteryIndex) of this
                          .reservedDroneArray.droneBatteries[index]
                          .required_batteries"
                        :key="batteries.required_batteries"
                      >
                        <td class="tdLeft"></td>
                        <td
                          class="tdRight"
                          v-if="
                            this.batteryArrayLength >=
                            this.reservedDroneArray.droneBatteries[index]
                              .required_batteries
                          "
                        >
                          <input
                            :list="'requiredBatteries' + batteryIndex"
                            name="requiredBatteriesSearch"
                            :id="
                              'requiredBatteriesSearch' + index + batteryIndex
                            "
                            class="request-input form-control requiredPilotSearchClass"
                            v-model="this.reqiredBatteries[index][batteryIndex]"
                            autocomplete="off"
                            @change="
                              batterySelect(
                                this.reqiredBatteries[index][batteryIndex],
                                index,
                                batteryIndex
                              )
                            "
                          />
                          <datalist :id="'requiredBatteries' + batteryIndex">
                            <option
                              v-for="item in this.batteryArray"
                              :key="item.lab_id"
                              :value="item.lab_id"
                            ></option>
                            <option></option>
                          </datalist>
                        </td>
                        <td
                          v-if="
                            this.batteryArrayLength <
                            this.reservedDroneArray.droneBatteries[index]
                              .required_batteries
                          "
                        >
                          <input
                            :list="'requiredBatteries' + batteryIndex"
                            name="requiredBatteriesSearch"
                            :id="
                              'requiredBatteriesSearch' + index + batteryIndex
                            "
                            class="request-input form-control"
                            autocomplete="off"
                            disabled
                            value="There isn't enough avalaible battery"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />
                <br />
                <button
                  class="btn btn-success"
                  type="button"
                  id="acceptButtonRequestsModal"
                  @click="
                    acceptRequest(this.selectedEvent, this.selectedEvent.index)
                  "
                >
                  <i class="fa-solid fa-check"></i> Accept
                </button>
                <button
                  class="btn btn-danger"
                  type="button"
                  id="declineButtonRequestsModal"
                  data-bs-dismiss="modal"
                  @click="
                    declineRequest(this.selectedEvent, this.selectedEvent.index)
                  "
                >
                  <i class="fa-solid fa-xmark"></i> Decline
                </button>

                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-secondary"
                  id="cancelButtonRequestsModal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-------------------------------------------------------- CONTAINER CARD ------------------------------------------------------------->
    <div class="containerCard">
      <!----------------------------------------------- RENT DRONE VIEW ---------------------------------------------------->

      <!-- -------------------------------------------------REQUESTS / WEATHER CARD / CALENDAR ROW ---------------------------------- -->
      <div class="row justify-content-around" id="first-row">
        <div class="card" id="greetings-card">
          <div
            class="requestsCard"
            v-show="
              (currentUser.role == 'admin' ||
                currentUser.role == 'semiAdmin' ||
                currentUser.role == 'developer') &&
              this.requests.length > 0
            "
          >
            <h5 class="card-title">
              <i class="fa-solid fa-circle-question"></i> Requests
            </h5>

            <div class="requestsCardCol col-auto">
              <div>
                <div id="requestTableDiv">
                  <table
                    class="requestTable table table-dark"
                    id="requests-table"
                  >
                    <thead>
                      <tr>
                        <th>Event:</th>
                        <th>Start:</th>
                        <th>End:</th>
                        <th>Accept:</th>
                        <th>Decline:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in requests" :key="item.id">
                        <td>
                          {{ this.requests[index].event_name }}
                        </td>
                        <td>
                          {{
                            new Date(
                              this.requests[index].date_start
                            ).toLocaleString()
                          }}
                        </td>
                        <td>
                          {{
                            new Date(
                              this.requests[index].date_end
                            ).toLocaleString()
                          }}
                        </td>
                        <td>
                          <button
                            class="btn btn-success"
                            type="button"
                            id="acceptButton"
                            data-bs-toggle="modal"
                            data-bs-target="#requestsModal"
                            @click="jumpToRequestModal(item, index)"
                          >
                            <i class="fa-solid fa-check"></i> Accept
                          </button>
                        </td>
                        <td>
                          <button
                            class="btn btn-danger"
                            type="button"
                            id="declineButton"
                            @click="declineRequest(item, index)"
                          >
                            <i class="fa-solid fa-xmark"></i> Decline
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- ---------------------------------------------WEATHER----------------------------------------------------- -->
          <div
            class="weather"
            v-show="currentUser.role == 'user' || this.requests.length == 0"
          >
            <iframe
              src="https://www.meteoblue.com/en/weather/widget/daily?geoloc=detect&days=7&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&precipunit=MILLIMETER&coloured=monochrome&pictoicon=0&pictoicon=1&maxtemperature=0&maxtemperature=1&mintemperature=0&mintemperature=1&windspeed=0&windspeed=1&windgust=0&winddirection=0&winddirection=1&uv=0&humidity=0&precipitation=0&precipitation=1&precipitationprobability=0&precipitationprobability=1&spot=0&pressure=0&layout=dark"
              frameborder="0"
              scrolling="NO"
              allowtransparency="true"
              sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
              id="weatherId"
            ></iframe>
            <div>
              <!-- DO NOT REMOVE THIS LINK --><a
                href="https://www.meteoblue.com/en/weather/week/index?utm_source=weather_widget&utm_medium=linkus&utm_content=daily&utm_campaign=Weather%2BWidget"
                target="_blank"
                rel="noopener"
                id="weatherLink"
                >meteoblue</a
              >
            </div>
          </div>
        </div>
        <div class="calendar-card card">
          <div class="calendar">
            <FullCalendar :options="calendarOptions" />
          </div>
        </div>
      </div>

      <div class="card" id="my-events">
        <div class="card-body">
          <h5 class="card-title">
            <i class="fa-solid fa-calendar-check"></i> My Events
          </h5>
          <p class="card-text">My upcomming events.</p>
          <div id="myEventsBody">
            <table class="table table-bordered" id="event-table">
              <thead>
                <tr>
                  <th scope="col">Event Name</th>
                  <th scope="col">Start:</th>
                  <th scope="col">End:</th>
                  <th scope="col">Reason:</th>
                  <th scope="col">Drone:</th>
                  <th scope="col">Status:</th>
                  <th scope="col">Upload files</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.myEvents" :key="item.event_id">
                  <td>{{ this.myEvents[index].event_name }}</td>
                  <td>
                    {{
                      new Date(this.myEvents[index].date_start).toLocaleString()
                    }}
                  </td>
                  <td>
                    {{
                      new Date(this.myEvents[index].date_end).toLocaleString()
                    }}
                  </td>
                  <td>
                    {{ this.myEvents[index].reason }}
                  </td>
                  <td>
                    {{ this.myEvents[index].drone_lab_id }}
                  </td>
                  <td v-if="this.myEvents[index].request_status == '#6e282f'">
                    Not accepted yet
                  </td>
                  <td v-if="this.myEvents[index].request_status == '#404C2B'">
                    Accepted
                  </td>
                  <td v-if="this.myEvents[index].request_status == '#808080'">
                    Drone Check
                  </td>

                  <td>
                    <button
                      v-if="
                        (this.myEvents[index].request_status == '#404C2B' &&
                          new Date(this.myEvents[index].date_start) <=
                            this.today &&
                          this.myEvents[index].after_check == false) ||
                        (this.myEvents[index].request_status == '#404C2B' &&
                          new Date(this.myEvents[index].date_start) <=
                            this.today &&
                          this.myEvents[index].after_check == true &&
                          (this.myEvents[index].get_contract == null ||
                            this.myEvents[index].give_contract == null))
                      "
                      class="btn btn-secondary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadBeforeAfterCheckModal"
                      id="uploadBeforeAfterCheckButton"
                      @click="selectEvent(item)"
                    >
                      Upload
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- --------------------------------PILOTS---------------------------------------- -->

      <div class="card" id="pilot-stats">
        <div class="card-body">
          <h5 class="card-title">Pilot availability:</h5>
          <p class="card-text">Who is working today?</p>
          <div class="row justify-content-center">
            <div class="card-group pilotCardGroup justify-content-center">
              <div
                v-for="(item, index) in this.availablePilots"
                :key="item.licence_id"
                id="pilot-card"
              >
                <div
                  class="card"
                  id="pilotCards"
                  v-show="this.availablePilots[index].deleted != true"
                >
                  <img
                    v-if="this.profilAvailable[index] != undefined"
                    :src="
                      this.apiUrl +
                      '/pilot_images/' +
                      this.profilAvailable[index]
                    "
                    alt="profil"
                    id="pilot-img"
                  />
                  <div class="card-body">
                    <p class="card-text" id="avaible-text">
                      {{
                        this.availablePilots[index].first_name +
                        " " +
                        this.availablePilots[index].last_name +
                        " can fly today"
                      }}
                    </p>
                    <p class="card-title pilotCardTitle">
                      {{ this.availablePilots[index].email }}
                    </p>
                    <p class="pilotCardTitle">
                      {{ this.availablePilots[index].licence_id }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-for="(item, index) in this.notAvailablePilots"
                :key="item.licence_id"
                id="pilot-card"
              >
                <div
                  class="card"
                  id="pilotCardId"
                  v-show="this.notAvailablePilots[index].deleted != true"
                >
                  <img
                    v-if="this.profilNotAvailable[index] != undefined"
                    :src="
                      this.apiUrl +
                      '/pilot_images/' +
                      this.profilNotAvailable[index]
                    "
                    alt="Avatar"
                    id="pilot-img"
                  />
                  <div class="card-body">
                    <p class="card-text" id="busy-text">
                      {{
                        this.notAvailablePilots[index].first_name +
                        " " +
                        this.notAvailablePilots[index].last_name +
                        " is busy today"
                      }}
                    </p>
                    <p class="card-title pilotCardTitle">
                      {{ this.notAvailablePilots[index].email }}
                    </p>
                    <p class="pilotCardTitle">
                      {{ this.notAvailablePilots[index].licence_id }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dataProvider from "../utils/dataProvider.js";
import { DateTime } from "luxon";

const dp = dataProvider();
export default {
  components: {
    FullCalendar,
  },

  methods: {
    //----------------------------------------EDIT EVENT -----------------------------------------------------------
    addEditRow(type, index) {
      console.log("add", type);
      console.log(this.eventEditDronesArray);
      switch (type) {
        case "drone":
          this.eventEditDronesArray.push({
            drone_lab_id: "New Drone",
            pilot_id: ["neededYes"],
          });
          this.selectedDrone.push([]);
          this.selectedPilotArray.push([[{ licence_id: "neededYes" }]]);
          this.selectedBattery.push([]);

          break;
        case "pilot":
          this.selectedPilotArray[index].push([[{ licence_id: "neededYes" }]]);
          this.eventEditDronesArray[index].pilot_id.push([]);
          break;
        default:
          break;
      }
    },
    removeEditRow(type, index) {
      console.log("remove", type);
      switch (type) {
        case "drone":
          if (this.eventEditDronesArray.length > 1) {
            this.eventEditDronesArray.pop();
            this.selectedDrone.pop();
            this.selectedPilotArray.pop();
            this.selectedBattery.pop();
          } else {
            alert(
              "Can't remove more drone, if you want to remove the last drone, delete the event"
            );
          }

          break;
        case "pilot":
          if (this.selectedPilotArray[index].length > 1) {
            this.selectedPilotArray[index].pop();
            this.eventEditDronesArray[index].pilot_id.pop();
          } else {
            alert(
              "Can't remove more pilots, if you want to remove the last pilot, please select the pilot called:'neededNo'"
            );
          }

          break;
        default:
          break;
      }
    },
    async jumpToEventEditModal() {
      this.eventEditPilotsArray = [];
      this.eventEditBatteriesArray = [];
      this.eventEditDroneBatteriesArray = [];
      this.eventEditDronesArray = await dp.getReservedDronesByEventId(
        this.clickedEvent.event_id
      );
      let batteries = await dp.getReservedBatteriesByEventId(
        this.clickedEvent.event_id
      );

      for (let index = 0; index < this.eventEditDronesArray.length; index++) {
        this.eventEditDroneBatteriesArray.push(
          await dp.getDroneBatteryById(
            this.eventEditDronesArray[index].drone_lab_id
          )
        );
        this.eventEditPilotsArray.push([]);
        for (
          let pilotindex = 0;
          pilotindex < this.eventEditDronesArray[index].pilot_id.length;
          pilotindex++
        ) {
          this.eventEditPilotsArray[index].push(
            await dp.getPilotById(
              this.eventEditDronesArray[index].pilot_id[pilotindex]
            )
          );
        }

        this.eventEditBatteriesArray.push([]);
        console.log(
          this.eventEditDroneBatteriesArray[index].required_batteries
        );
        for (
          let batteryIndex =
            this.eventEditDroneBatteriesArray[index].required_batteries - 1;
          batteryIndex >= 0;
          batteryIndex--
        ) {
          this.eventEditBatteriesArray[index].push(batteries[batteryIndex]);
          batteries.splice(batteryIndex, 1);
        }
      }
      /* 
      console.log(this.eventEditDroneBatteriesArray);
      console.log(this.eventEditBatteriesArray);

      console.log(this.eventEditDronesArray);

      console.log(this.eventEditPilotsArray); */

      this.eventEnd = DateTime.fromISO(this.clickedEvent.date_end).toFormat(
        "yyyy-MM-dd"
      );
      this.eventEndTime = DateTime.fromISO(this.clickedEvent.date_end).toFormat(
        "HH:mm"
      );
      this.eventStart = DateTime.fromISO(this.clickedEvent.date_start).toFormat(
        "yyyy-MM-dd"
      );
      this.eventStartTime = DateTime.fromISO(
        this.clickedEvent.date_start
      ).toFormat("HH:mm");

      //get freedrones,free pilots,free batteries with
      this.getAvailableThingsFirst();

      this.selectedDrone = [];
      for (let index = 0; index < this.eventEditDronesArray.length; index++) {
        this.selectedDrone[index] =
          this.eventEditDronesArray[index].drone_lab_id;
      }
      this.selectedPilotArray = [...this.eventEditPilotsArray];
      this.selectedBattery = [];
      for (
        let index = 0;
        index < this.eventEditBatteriesArray.length;
        index++
      ) {
        this.selectedBattery.push([]);
        for (
          let batteryIndex = 0;
          batteryIndex < this.eventEditBatteriesArray[index].length;
          batteryIndex++
        ) {
          this.selectedBattery[index][batteryIndex] =
            this.eventEditBatteriesArray[index][batteryIndex].battery_lab_id;
        }
      }
    },
    async acceptEventEdit() {
      let eventEditOk = false;
      let droneEditOk = true;
      let pilotEditOk = true;
      let batteryEditOk = true;
      let allOk = false;

      //-----------------------------EDIT EVENT STUFF----------------------------------------------
      let eventName = document.getElementById("eventEditEventName").value;
      let eventEditStartDate = DateTime.fromISO(
        this.eventStart + "T" + this.eventStartTime + ":00"
      )
        .toLocal()
        .toUTC()
        .toISO();
      let eventEditEndDate = DateTime.fromISO(
        this.eventEnd + "T" + this.eventEndTime + ":00"
      )
        .toLocal()
        .toUTC()
        .toISO();

      ///-----------------------after event drone check dates-----------------------
      let eventEditStartDateCheck = DateTime.fromISO(
        this.eventEnd + "T" + "00:00:00"
      )
        .toLocal()
        .toUTC()
        .plus({ days: 1 })
        .toISO();
      let eventEditEndDateCheck = DateTime.fromISO(
        this.eventEnd + "T" + "23:59:00"
      )
        .toLocal()
        .toUTC()
        .plus({ days: 1 })
        .toISO();
      let reason = document.getElementById("eventEditReason").value;
      //console.log(eventName!="", eventEditStartDate,eventEditEndDate,reason!="", eventEditStartDateCheck,eventEditEndDateCheck);
      if (
        eventName != "" &&
        eventEditStartDate &&
        eventEditEndDate &&
        reason != "" &&
        eventEditStartDateCheck &&
        eventEditEndDateCheck
      ) {
        eventEditOk = true;
      } else {
        alert("Something is wrong with the selected event data!");
      }
      //-----------------------------EDIT EVENT STUFF END----------------------------------------------
      //-----------------------------EDIT DRONE STUFF------------------------------------------
      //console.log(this.selectedDrone)
      for (let index = 0; index < this.selectedDrone.length; index++) {
        let foundIndex = this.avaliableDroneArray.findIndex(
          (obj) => obj.lab_id == this.selectedDrone[index]
        );
        if (foundIndex == -1) {
          droneEditOk = false;
          alert("Something is wrong with the selected Drones!");
          break;
        }
      }
      //----------------------------- EDIT DRONE STUFF END ------------------------------------------

      //----------------------------- EDIT PILOT STUFF ------------------------------------------

      /*  console.log(this.selectedPilotArray);
      console.log(this.availablePilotsEventEdit); */
      for (let index = 0; index < this.selectedPilotArray.length; index++) {
        for (
          let pilotIndex = 0;
          pilotIndex < this.selectedPilotArray[index].length;
          pilotIndex++
        ) {
          let foundIndex = this.availablePilotsEventEdit.findIndex(
            (obj) =>
              obj.licence_id ==
              this.selectedPilotArray[index][pilotIndex].licence_id
          );
          //console.log(foundIndex);
          if (foundIndex == -1) {
            pilotEditOk = false;
            alert("Something is wrong with the selected Pilots!");
            break;
          }
        }
      }
      //----------------------------- EDIT PILOT STUFF END------------------------------------------
      //----------------------------- EDIT BATTERY STUFF -------------------------------------------

      for (let index = 0; index < this.selectedBattery.length; index++) {
        for (
          let batteryIndex = 0;
          batteryIndex < this.selectedBattery[index].length;
          batteryIndex++
        ) {
          console.log(this.selectedBattery[index]);

          let foundIndex = this.availableBatteryArrayEventEdit.findIndex(
            (obj) => obj.lab_id == this.selectedBattery[index][batteryIndex]
          );
          if (foundIndex == -1) {
            batteryEditOk = false;
            alert("Something is wrong with the selected Batteries!");
            break;
          }
        }
      }
      //----------------------------- EDIT BATTERY STUFF END -------------------------------------------
      //----------------------------- EVENT EDIT ----------------------------------------------
      if (eventEditOk && droneEditOk && pilotEditOk && batteryEditOk) {
        allOk = true;
      }
      console.log(allOk);
      if (allOk) {
        //--------------------- Event edit part ------------------------------------- EZ OK
        console.log(this.clickedEvent.event_id);
        await dp.editEvent(
          this.clickedEvent.event_id,
          eventName,
          eventEditStartDate,
          eventEditEndDate,
          undefined,
          reason
        );
        await dp.editEvent(
          this.clickedEvent.event_id + 1,
          "Drone check after event: "+eventName,
          eventEditStartDateCheck,
          eventEditEndDateCheck,
          undefined,
          undefined
        );
        //--------------------- Event edit part END -------------------------------------EZ OK

        //--------------------- Drone edit part ----------------------------------------- EDDIG OK

        let selectedPilotArrayModify = [];

        let reservedDronesOriginal = await dp.getReservedDronesByEventId(
          this.clickedEvent.event_id
        );
        console.log(this.selectedDrone.length);
        for (let index = 0; index < this.selectedPilotArray.length; index++) {
          selectedPilotArrayModify.push([]);
          for (
            let pilotIndex = 0;
            pilotIndex < this.selectedPilotArray[index].length;
            pilotIndex++
          ) {
            selectedPilotArrayModify[index][pilotIndex] =
              this.selectedPilotArray[index][pilotIndex].licence_id;
          }
        }
        if (reservedDronesOriginal.length == this.selectedDrone.length) {
          console.log("csak edit");
          for (let index = 0; index < reservedDronesOriginal.length; index++) {
            await dp.editReservedDroneById(
              reservedDronesOriginal[index].id,
              undefined,
              selectedPilotArrayModify[index],
              undefined,
              this.selectedDrone[index],
              undefined,
              undefined,
              undefined,
              undefined
            );
            await dp.editReservedDroneById(
              reservedDronesOriginal[index].id + 1,
              undefined,
              selectedPilotArrayModify[index],
              undefined,
              this.selectedDrone[index],
              undefined,
              undefined,
              undefined,
              undefined
            );
          }
        } else if (reservedDronesOriginal.length > this.selectedDrone.length) {
          console.log("delete és edit");
          console.log(reservedDronesOriginal);
          console.log(this.selectedDrone);
          for (
            let index = this.selectedDrone.length;
            index < reservedDronesOriginal.length;
            index++
          ) {
            console.log(reservedDronesOriginal[index].id);
            await dp.deleteReservedDroneById(reservedDronesOriginal[index].id);
            await dp.deleteReservedDroneById(
              reservedDronesOriginal[index].id + 1
            );
          }
          reservedDronesOriginal = await dp.getReservedDronesByEventId(
            this.clickedEvent.event_id
          );
          for (let index = 0; index < reservedDronesOriginal.length; index++) {
            await dp.editReservedDroneById(
              reservedDronesOriginal[index].id,
              undefined,
              selectedPilotArrayModify[index],
              undefined,
              this.selectedDrone[index],
              undefined,
              undefined,
              undefined,
              undefined
            );
            await dp.editReservedDroneById(
              reservedDronesOriginal[index].id + 1,
              undefined,
              selectedPilotArrayModify[index],
              undefined,
              this.selectedDrone[index],
              undefined,
              undefined,
              undefined,
              undefined
            );
          }
        } else {
          //reservedDronesOriginal.length < this.selectedDrone.length
          console.log("edit és add");
          for (
            let index = reservedDronesOriginal.length;
            index < this.selectedDrone.length;
            index++
          ) {
            console.log(reservedDronesOriginal);
            console.log(this.selectedDrone);
            await dp.addDroneToEvent(
              this.clickedEvent.event_id,
              reservedDronesOriginal[0].user_uuid,
              ["neededYes"],
              this.selectedDrone[index]
            );
            await dp.addDroneToEvent(
              this.clickedEvent.event_id + 1,
              reservedDronesOriginal[0].user_uuid,
              ["neededNo"],
              this.selectedDrone[index]
            );
          }
          reservedDronesOriginal = await dp.getReservedDronesByEventId(
            this.clickedEvent.event_id
          );
          for (let index = 0; index < reservedDronesOriginal.length; index++) {
            await dp.editReservedDroneById(
              reservedDronesOriginal[index].id,
              undefined,
              selectedPilotArrayModify[index],
              undefined,
              this.selectedDrone[index],
              undefined,
              undefined,
              undefined,
              undefined
            );
            await dp.editReservedDroneById(
              reservedDronesOriginal[index].id + 1,
              undefined,
              selectedPilotArrayModify[index],
              undefined,
              this.selectedDrone[index],
              undefined,
              undefined,
              undefined,
              undefined
            );
          }
        }
        //--------------------- Drone edit part END ------------------------------------- EDDIG OK
        //--------------------- Battery edit part -----------------------------------------
        let reservedBatteriesOriginal = await dp.getReservedBatteriesByEventId(
          this.clickedEvent.event_id
        );
        console.log(reservedBatteriesOriginal);
        let selectedBatteryArrayModify = [];
        for (let index = 0; index < this.selectedBattery.length; index++) {
          for (
            let batteryIndex = 0;
            batteryIndex < this.selectedBattery[index].length;
            batteryIndex++
          ) {
            selectedBatteryArrayModify.push(
              this.selectedBattery[index][batteryIndex]
            );
          }
        }
        selectedPilotArrayModify = [];
        for (let index = 0; index < this.selectedPilotArray.length; index++) {
          selectedPilotArrayModify.push([]);
          for (
            let pilotIndex = 0;
            pilotIndex < this.selectedPilotArray[index].length;
            pilotIndex++
          ) {
            selectedPilotArrayModify[index][pilotIndex] =
              this.selectedPilotArray[index][pilotIndex].licence_id;
          }
        }
        console.log(selectedPilotArrayModify);

        if (
          reservedBatteriesOriginal.length == selectedBatteryArrayModify.length
        ) {
          console.log("csere");
          let reservedIndex = 0;
          for (let index = 0; index < this.selectedBattery.length; index++) {
            for (
              let batteryIndex = 0;
              batteryIndex < this.selectedBattery[index].length;
              batteryIndex++
            ) {
              await dp.editReservedBatteryById(
                reservedBatteriesOriginal[reservedIndex].id,
                undefined,
                selectedPilotArrayModify[index],
                undefined,
                this.selectedBattery[index][batteryIndex],
                undefined
              );
              await dp.editReservedBatteryById(
                reservedBatteriesOriginal[reservedIndex].id + 1,
                undefined,
                undefined,
                undefined,
                this.selectedBattery[index][batteryIndex],
                undefined
              );
              reservedIndex++;
            }
          }
        } else if (
          reservedBatteriesOriginal.length > selectedBatteryArrayModify.length
        ) {
          console.log("törlés és csere");
          for (
            let index = selectedBatteryArrayModify.length;
            index < reservedBatteriesOriginal.length;
            index++
          ) {
            await dp.deleteReservedBatteryById(
              reservedBatteriesOriginal[index].id
            );
            await dp.deleteReservedBatteryById(
              reservedBatteriesOriginal[index].id + 1
            );
          }
          let reservedIndex = 0;
          for (let index = 0; index < this.selectedBattery.length; index++) {
            for (
              let batteryIndex = 0;
              batteryIndex < this.selectedBattery[index].length;
              batteryIndex++
            ) {
              await dp.editReservedBatteryById(
                reservedBatteriesOriginal[reservedIndex].id,
                undefined,
                selectedPilotArrayModify[index],
                undefined,
                this.selectedBattery[index][batteryIndex],
                undefined
              );
              await dp.editReservedBatteryById(
                reservedBatteriesOriginal[reservedIndex].id + 1,
                undefined,
                undefined,
                undefined,
                this.selectedBattery[index][batteryIndex],
                undefined
              );
              reservedIndex++;
            }
          }
        } else {
          console.log("add és csere");
          console.log(reservedBatteriesOriginal);
          console.log(selectedBatteryArrayModify);
          for (
            let index = reservedBatteriesOriginal.length;
            index < selectedBatteryArrayModify.length;
            index++
          ) {
            console.log(selectedBatteryArrayModify[index]);
            await dp.addBatteryToEvent(
              this.clickedEvent.event_id,
              reservedBatteriesOriginal[0].user_uuid,
              ["neededYes"],
              selectedBatteryArrayModify[index]
            );
            await dp.addBatteryToEvent(
              this.clickedEvent.event_id + 1,
              reservedBatteriesOriginal[0].user_uuid,
              ["neededNo"],
              selectedBatteryArrayModify[index]
            );
          }
          reservedBatteriesOriginal = await dp.getReservedBatteriesByEventId(
            this.clickedEvent.event_id
          );
          console.log(reservedBatteriesOriginal);
          console.log(this.selectedBattery);
          let reservedIndex = 0;
          for (let index = 0; index < this.selectedBattery.length; index++) {
            for (
              let batteryIndex = 0;
              batteryIndex < this.selectedBattery[index].length;
              batteryIndex++
            ) {
              console.log(reservedBatteriesOriginal[reservedIndex].id);
              await dp.editReservedBatteryById(
                reservedBatteriesOriginal[reservedIndex].id,
                undefined,
                selectedPilotArrayModify[index],
                undefined,
                this.selectedBattery[index][batteryIndex],
                undefined
              );
              await dp.editReservedBatteryById(
                reservedBatteriesOriginal[index + batteryIndex].id + 1,
                undefined,
                undefined,
                undefined,
                this.selectedBattery[index][batteryIndex],
                undefined
              );
              reservedIndex++;
            }
          }
        }
        //--------------------- Battery edit part END -------------------------------------
        document.getElementById("cancelButtonEventEdit").click();
        document.getElementById("eventModalClose").click();
        //--------------------- Refresh part of the page-------------------------------------
        //--- refresh events in Calendar----------
        this.calendarOptions.events=[]
        let events = await dp.getEventsNotDeleted();
    for (let element of events) {
      this.calendarOptions.events.push({
        title: element.event_name,
        start: element.date_start,
        end: element.date_end,
        backgroundColor: element.request_status,
        id: element.event_id,
      });
    }
        //----Refresh events in my events part--------------------
        this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
          this.currentUser.uuid
        );
        //Refresh available Pilots
    this.getAvailablePilots();

      }
    },

    async getAvailableThingsFirst() {
      if (this.eventStart && this.eventEnd) {
        let eventEditStartDate = DateTime.fromISO(
          this.eventStart + "T" + this.eventStartTime + ":00"
        )
          .toLocal()
          .toUTC()
          .toISO();
        let eventEditEndDate = DateTime.fromISO(
          this.eventEnd + "T" + this.eventEndTime + ":00"
        )
          .toLocal()
          .toUTC()
          .plus({ days: 1 })
          .toISO();
        let occupiedEvents = await dp.getEventByDate(
          eventEditStartDate,
          eventEditEndDate
        );

        ///---------get availalble drones------------------
        let occupiedDrones = [];
        let occupiedBatteries = [];
        this.avaliableDroneArray = [...this.droneArray];
        for (let events of occupiedEvents) {
          let drones = await dp.getReservedDronesByEventId(events.event_id);

          let batteries = await dp.getReservedBatteriesByEventId(
            events.event_id
          );
          for (const battery of batteries) {
            occupiedBatteries.push(battery);
          }
          for (let reservedDrones of drones) {
            occupiedDrones.push(reservedDrones);
          }
        }
        for (let index = 0; index < occupiedDrones.length; index++) {
          for (let i = 0; i < this.avaliableDroneArray.length; i++) {
            if (
              this.avaliableDroneArray[i].lab_id ==
                occupiedDrones[index].drone_lab_id &&
              occupiedDrones[index].drone_lab_id != undefined
            ) {
              this.avaliableDroneArray.splice(i, 1);
              i = this.avaliableDroneArray.length;
            }
          }
        }
        //--------add current drones to available drones
        for (let index = 0; index < this.eventEditDronesArray.length; index++) {
          let foundIndex = this.avaliableDroneArray.findIndex(
            (obj) => obj.lab_id == this.eventEditDronesArray[index]
          );
          if (foundIndex == -1) {
            this.avaliableDroneArray.push({
              lab_id: this.eventEditDronesArray[index].drone_lab_id,
            });
          }
        }

        //--------get available Batteries----------------------
        this.batteryArrayEventEdit = await dp.getBatteryNotDeleted();
        this.availableBatteryArrayEventEdit = [...this.batteryArrayEventEdit];
        for (const batteries of occupiedBatteries) {
          let foundIndex = this.availableBatteryArrayEventEdit.findIndex(
            (obj) => obj.lab_id == batteries.battery_lab_id
          );
          if (foundIndex != -1) {
            this.availableBatteryArrayEventEdit.splice(foundIndex, 1);
            //this.batteryArrayEventEdit.splice(foundIndex, 1);
          }
        }
        //--------add current batteries to available batteries
        for (
          let index = 0;
          index < this.batteryArrayEventEdit.length;
          index++
        ) {
          let foundIndex = this.availableBatteryArrayEventEdit.findIndex(
            (obj) => obj.lab_id == this.batteryArrayEventEdit[index].lab_id
          );
          if (foundIndex == -1) {
            this.availableBatteryArrayEventEdit.push({
              lab_id: this.batteryArrayEventEdit[index].lab_id,
            });
          }
        }
        /* let foundIndex = this.batteryArrayyEventEdit.findIndex(
        (obj) => obj.lab_id == batteryName
      ); */
        //--------get available pilots------------
        this.pilotArrayEventEdit = await dp.getPilotNotDeleted();

        this.allPilotArrayEventEdit = [...this.pilotArrayEventEdit];
        this.availablePilotsEventEdit = [...this.pilotArrayEventEdit];

        for (const drones of occupiedDrones) {
          for (let index = 0; index < drones.pilot_id.length; index++) {
            let foundIndex = this.allPilotArrayEventEdit.findIndex(
              (obj) => obj.licence_id == drones.pilot_id[index]
            );
            if (foundIndex != -1) {
              this.availablePilotsEventEdit.splice(foundIndex, 1);
              this.allPilotArrayEventEdit.splice(foundIndex, 1);
            }
          }
        }
        ///----------------------Add current pilots to available pilots--------------------------------------
        this.availablePilotsEventEdit.push({
          licence_id: "neededNo",
        });
        for (let index = 0; index < this.eventEditDronesArray.length; index++) {
          for (
            let pilotIndex = 0;
            pilotIndex < this.eventEditDronesArray[index].pilot_id.length;
            pilotIndex++
          ) {
            let foundIndex = this.availablePilotsEventEdit.findIndex(
              (obj) =>
                obj.licence_id ==
                this.eventEditDronesArray[index].pilot_id[pilotIndex]
            );
            if (foundIndex == -1) {
              this.availablePilotsEventEdit.push({
                licence_id:
                  this.eventEditDronesArray[index].pilot_id[pilotIndex],
              });
            }
          }
        }
      }
    },
    async selectEventEditDate() {
      if (this.eventStart && this.eventEnd) {
        let occupiedEvents = await dp.getEventByDate(
          DateTime.fromISO(this.eventStart + "T" + this.eventStartTime + ":00")
            .toLocal()
            .toUTC()
            .toISO(),
          DateTime.fromISO(this.eventEnd + "T" + this.eventEndTime + ":00")
            .toLocal()
            .toUTC()
            .plus({ days: 1 })
            .toISO()
        );

        let occupiedDrones = [];
        this.avaliableDroneArray = [...this.droneArray];
        for (let events of occupiedEvents) {
          let drones = await dp.getReservedDronesByEventId(events.event_id);
          for (let reservedDrones of drones) {
            occupiedDrones.push(reservedDrones);
          }
        }
        for (let index = 0; index < occupiedDrones.length; index++) {
          for (let i = 0; i < this.avaliableDroneArray.length; i++) {
            if (
              this.avaliableDroneArray[i].lab_id ==
                occupiedDrones[index].drone_lab_id &&
              occupiedDrones[index].drone_lab_id != undefined
            ) {
              this.avaliableDroneArray.splice(i, 1);
              i = this.avaliableDroneArray.length;
            }
          }
        }
      }
    },

    async selectDrone(index) {
      this.eventEditPilotsArray = [];
      this.eventEditBatteriesArray = [];
      this.eventEditDroneBatteriesArray = [];
      this.selectedBattery = [];
      let droneData = await dp.getDroneById(this.selectedDrone[index]);
      if (!droneData) {
        document.getElementById("editEventDrones" + index).style.border =
          "medium solid #c9021d";
      } else {
        this.eventEditDronesArray[index] = {
          after_check: false,
          drone_lab_id: droneData.lab_id,
          event_id: this.eventEditDronesArray[index].event_id,
          field_check: false,
          free: false,
          id: this.eventEditDronesArray[index].id,
          lab_check: false,
          pilot_id: this.eventEditDronesArray[index].pilot_id,
          user_uuid: this.eventEditDronesArray[index].user_uuid,
        };
        document.getElementById("editEventDrones" + index).style.border = "";
      }

      for (let index = 0; index < this.eventEditDronesArray.length; index++) {
        this.eventEditDroneBatteriesArray.push(
          await dp.getDroneBatteryById(
            this.eventEditDronesArray[index].drone_lab_id
          )
        );
        let batteries = await dp.getReservedBatteriesByEventId(
          this.clickedEvent.event_id
        );
        this.eventEditPilotsArray.push([]);
        for (
          let pilotindex = 0;
          pilotindex < this.eventEditDronesArray[index].pilot_id.length;
          pilotindex++
        ) {
          this.eventEditPilotsArray[index].push(
            await dp.getPilotById(
              this.eventEditDronesArray[index].pilot_id[pilotindex]
            )
          );
        }
        this.eventEditBatteriesArray.push([]);
        this.selectedBattery.push([]);
        console.log("BATTERIES", batteries);
        console.log(
          "BATTERIES 2:",
          this.eventEditDroneBatteriesArray[index].required_batteries
        );

        for (
          let batteryIndex = 0;
          batteryIndex <=
          this.eventEditDroneBatteriesArray[index].required_batteries - 1;
          batteryIndex++
        ) {
          this.eventEditBatteriesArray[index].push(batteries[batteryIndex]);
          this.selectedBattery[index].push(
            batteries[batteryIndex].battery_lab_id
          );
        }
      }
    },
    async selectPilotEventEdit(index, pilotindex) {
      let pilotData = await dp.getPilotById(
        this.selectedPilotArray[index][pilotindex].licence_id
      );
      if (!pilotData) {
        document.getElementById(
          "editEventPilots" + index + pilotindex
        ).style.border = "medium solid #c9021d";
      } else {
        this.eventEditPilotsArray[index][pilotindex] = pilotData;
        document.getElementById(
          "editEventPilots" + index + pilotindex
        ).style.border = "";
      }
    },

    async selectBatteryEventEdit(index, batteryIndex) {
      let batteryData = await dp.getBatteryById(
        this.selectedBattery[index][batteryIndex]
      );
      if (!batteryData) {
        document.getElementById(
          "editEventBatteries" + index + batteryIndex
        ).style.border = "medium solid #c9021d";
      } else {
        this.eventEditBatteriesArray[index][batteryIndex] = {
          battery_lab_id: batteryData.lab_id,
          event_id: this.eventEditBatteriesArray[index][batteryIndex].event_id,
          free: this.eventEditBatteriesArray[index][batteryIndex].free,
          id: this.eventEditBatteriesArray[index][batteryIndex].id,
          pilot_id: this.eventEditBatteriesArray[index][batteryIndex].pilot_id,
          user_uuid:
            this.eventEditBatteriesArray[index][batteryIndex].user_uuid,
        };
        document.getElementById(
          "editEventBatteries" + index + batteryIndex
        ).style.border = "";
      }
    },

    //------------------------------------HANDOVER CONTRACT----------------------------------------------------
    async uploadHandoverContract(type) {
      let pdf;
      switch (type) {
        case "get":
          pdf = document.getElementById("toDDCHandoverContractUpload").files[0];
          if (pdf) {
            if (pdf.type == "application/pdf") {
              await dp.uploadHandoverContract(pdf, this.actualEventId, type);
              this.actualEvent.get_contract = "1";
              document.getElementById("closeHandoverModalget").click();
            } else {
              document.getElementById(
                "toDDCHandoverContractUpload"
              ).style.border = "medium solid #c9021d";
            }
          } else {
            document.getElementById(
              "toDDCHandoverContractUpload"
            ).style.border = "medium solid #c9021d";
          }
          break;
        case "give":
          pdf = document.getElementById("fromDDCHandoverContractUpload")
            .files[0];
          if (pdf) {
            if (pdf.type == "application/pdf") {
              await dp.uploadHandoverContract(pdf, this.actualEventId, type);
              this.actualEvent.give_contract = "2";
              document.getElementById("closeHandoverModalgive").click();
            } else {
              document.getElementById(
                "fromDDCHandoverContractUpload"
              ).style.border = "medium solid #c9021d";
            }
          } else {
            document.getElementById(
              "fromDDCHandoverContractUpload"
            ).style.border = "medium solid #c9021d";
          }
          break;

        default:
          break;
      }
      this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
        this.currentUser.uuid
      );
    },
    async toDDCHandoverContract() {
      window.open(this.apiUrl + "/toDDCHandoverContract/" + this.actualEventId);
    },
    async fromDDCHandoverContract() {
      window.open(
        this.apiUrl + "/fromDDCHandoverContract/" + this.actualEventId
      );
    },
    /* ---------------BUG REPORT--------------- */
    async sendBugRep() {
      this.developerEmail = [];
      for (const user of this.userArray) {
        if (user.role == "developer") {
          this.developerEmail.push(user.email);
        }
      }
      try {
        let from = "dron@tilb.sze.hu";
        let to = this.developerEmail;
        let subject = "Bug report bejelentés a Drónfoglaló rendszerhez";
        let text = this.bugRepCommentVmod;
        await dp.sendMail(from, to, subject, text);
      } catch (error) {
        console.log(error);
      }
      document.getElementById("cancelButtonBugRep").click();
    },
    ///////////////----------------------------------SEND MAIL-------------------------------------------
    async sendMail(reservedDronesArray, status, event) {
      console.log(reservedDronesArray);
      if (status == "accepted") {
        let userArray = [];
        let pilotArray = [];
        for (let index = 0; index < reservedDronesArray.length; index++) {
          userArray.push(
            await dp.getUserByUuid(reservedDronesArray[index].user_uuid)
          );
          for (
            let pilotIndex = 0;
            pilotIndex < reservedDronesArray[index].pilot_id.length;
            pilotIndex++
          ) {
            pilotArray.push(
              await dp.getPilotById(
                reservedDronesArray[index].pilot_id[pilotIndex]
              )
            );
          }
        }
        let pilotEmail = [];
        for (let index = 0; index < pilotArray.length; index++) {
          let getPilotUser = await dp.getUserByUuid(
            pilotArray[index].user_uuid
          );
          pilotEmail.push(getPilotUser.email);
        }
        let userEmail = [];
        for (let index = 0; index < userArray.length; index++) {
          userEmail.push(userArray[index].email);
        }
        let from = "dron@tilb.sze.hu";

        let to = userEmail + ", " + pilotEmail;
        let subject = "Drone request " + status;
        let text =
          "Tisztelt felhasználó " +
          "\n Az ön által létrehozott event, melynek neve: " +
          event.event_name +
          ",  " +
          status +
          " státuszúra változott.";

        await dp.sendMail(from, to, subject, text);
      } else {
        let reservedDroneArray2 = await dp.getReservedDronesByEventId(
          event.event_id
        );
        let userArray = [];
        for (let index = 0; index < reservedDroneArray2.length; index++) {
          userArray.push(
            await dp.getUserByUuid(reservedDroneArray2[index].user_uuid)
          );
        }
        let userEmail = [];
        for (let index = 0; index < userArray.length; index++) {
          userEmail.push(userArray[index].email);
        }
        let from = "dron@tilb.sze.hu";

        let to = userEmail;
        let subject = "Drone request rejected";
        let text =
          "Tisztelt felhasználó " +
          "\n Az ön által létrehozott event, melynek neve: " +
          event.event_name +
          ",  " +
          "rejected státuszúra változott.";

        await dp.sendMail(from, to, subject, text);
      }
    },
    async downloadDocxFiles() {
      window.open(this.apiUrl + "/sampleDocx/afterFlightPdf");
    },

    showPassword(key) {
      switch (key) {
        case "password":
          if (document.getElementById("passwordInput").type == "password") {
            document.getElementById("passwordInput").type = "text";
            document.getElementById("pwEye").style.display = "none";
            document.getElementById("pwSlashedEye").style.display = "block";
          } else if (document.getElementById("passwordInput").type == "text") {
            document.getElementById("passwordInput").type = "password";
            document.getElementById("pwEye").style.display = "block";
            document.getElementById("pwSlashedEye").style.display = "none";
          }
          break;
        case "passwordAgain":
          if (
            document.getElementById("passwordAgainInput").type == "password"
          ) {
            document.getElementById("passwordAgainInput").type = "text";
            document.getElementById("pwAgainEye").style.display = "none";
            document.getElementById("pwAgainSlashedEye").style.display =
              "block";
          } else if (
            document.getElementById("passwordAgainInput").type == "text"
          ) {
            document.getElementById("passwordAgainInput").type = "password";
            document.getElementById("pwAgainEye").style.display = "block";
            document.getElementById("pwAgainSlashedEye").style.display = "none";
          }
          break;
        default:
          break;
      }
    },

    async eventModalDelete(id) {
      if (
        confirm(
          `Are you sure you want to delete this event: ${this.clickedEvent.event_name}`
        )
      ) {
        for (
          let index = 0;
          index < this.calendarOptions.events.length;
          index++
        ) {
          const element = this.calendarOptions.events[index];
          if (element.id == id) {
            this.calendarOptions.events.splice(index, 1);
            index = this.calendarOptions.events.length;
          }
        }
        console.log("delete");

        await dp.softDeleteReservedDroneByEventId(id);
        await dp.softDeleteReservedDroneByEventId(id + 1);
        await dp.softDeleteReservedBatteriesByEventId(id);
        await dp.softDeleteReservedBatteriesByEventId(id + 1);
        await dp.softDeleteEventById(id);
        await dp.softDeleteEventById(id + 1);

        this.requests.splice(id, 1);
        this.calendarOptions.events = [];
        let events = await dp.getEventsNotDeleted();
        for (let element of events) {
          this.calendarOptions.events.push({
            title: element.event_name,
            start: element.date_start,
            end: element.date_end,
            backgroundColor: element.request_status,
            id: element.event_id,
          });
        }
      }
      this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
        this.currentUser.uuid
      );
    },

    selectUser(item) {
      this.selectedUser = item;
    },
    async listPage() {
      this.$router.push("/listPage");
    },
    ///--------------------------------------------EDIT USER----------------------------------------------
    async userEdit() {
      let editSelectPhoneNum = false;
      let editPhoneNum = false;
      let editPassword = false;
      let editPasswordAgain = false;

      let validatePassword = (password) => {
        return String(password).match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
      };
      let validatePhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{6,7})$/);
      };
      let validateSelectedPhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{2})$/);
      };

      if (
        validatePassword(this.editPassword) ||
        this.editPassword == undefined
      ) {
        document.getElementById("passwordInput").style.border =
          "medium solid #3f9140";
        editPassword = true;
      } else {
        document.getElementById("passwordInput").style.border =
          "medium solid #c9021d";
        alert(
          "Password must contain: \n -at least 8 characters \n -at least 1 number \n -at least 1 lowercase character\n -at least 1 uppercase character"
        );
      }

      if (this.editPassword == this.editPasswordAgain) {
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #3f9140";
        editPasswordAgain = true;
      } else {
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #c9021d";
        alert("Passwords doesn't match");
      }

      this.editEmail = document.getElementById("emailInput").placeholder;
      document.getElementById("emailInput").style.border =
        "medium solid #3f9140";

      if (
        validateSelectedPhoneNum(this.selectedNum) ||
        this.selectedNum == undefined
      ) {
        this.selectedNum = this.selectedNum || this.phoneselectedPlaceholder;
        document.getElementById("editPhoneNumSelectInput").style.border =
          "medium solid #3f9140";
        editSelectPhoneNum = true;
      } else {
        document.getElementById("editPhoneNumSelectInput").style.border =
          "medium solid #c9021d";
      }

      if (
        validatePhoneNum(this.editPhoneNum) ||
        this.editPhoneNum == undefined
      ) {
        this.editPhoneNum =
          this.editPhoneNum ||
          document.getElementById("phoneNumInput").placeholder;
        document.getElementById("phoneNumInput").style.border =
          "medium solid #3f9140";
        editPhoneNum = true;
      } else {
        document.getElementById("phoneNumInput").style.border =
          "medium solid #c9021d";
      }

      this.editFirstName =
        this.editFirstName ||
        document.getElementById("firstNameInput").placeholder;

      document.getElementById("firstNameInput").style.border =
        "medium solid #3f9140";

      this.editLastName =
        this.editLastName ||
        document.getElementById("lastNameInput").placeholder;
      document.getElementById("lastNameInput").style.border =
        "medium solid #3f9140";

      this.editOrganization =
        this.editOrganization || this.selectedOrganization;
      document.getElementById("organizationInput1").style.border =
        "medium solid #3f9140";
      document.getElementById("organizationInput").style.border =
        "medium solid #3f9140";

      document.getElementById("plusPhone").style.border =
        "medium solid #3f9140";
      document.getElementById("roleInput").style.border =
        "medium solid #3f9140";

      if (
        editPassword &&
        editPasswordAgain &&
        editSelectPhoneNum &&
        editPhoneNum
      ) {
        await dp.editUserByEmail(
          this.editPassword,
          this.editEmail,
          this.plusPhone + this.selectedNum + this.editPhoneNum,
          this.editRole,
          this.editFirstName,
          this.editLastName,
          this.editOrganization
        );
        this.userArray = await dp.getUserListNotDeleted();
        this.currentUser = await dp.getUserByEmail(this.editEmail);
        this.phoneNumPlaceholder = await this.currentUser.phone_num.slice(
          5,
          12
        );
        this.phoneselectedPlaceholder = await this.currentUser.phone_num.slice(
          3,
          5
        );
        this.editUsername = undefined;
        this.editPassword = undefined;
        this.selectedNum = undefined;
        this.editPasswordAgain = undefined;
        this.editEmail = undefined;
        this.editPhoneNum = undefined;
        this.editFirstName = undefined;
        this.editLastName = undefined;
        this.editOrganization = undefined;
        document.getElementById("cancelButtonUserEdit").click();
        //alert("Editing was successfull!");
      }
    },
    ///--------------------------------------------EDIT ANOTHER USER----------------------------------------------
    async anotherUserEdit() {
      this.editEmail = document.getElementById(
        "anotherUserEmailInput"
      ).placeholder;

      this.selectedNum = this.selectedNum || this.phoneselectedPlaceholder;

      this.editPhoneNum =
        this.editPhoneNum ||
        document.getElementById("anotherUserPhoneNumInput").placeholder;

      this.editFirstName =
        this.editFirstName ||
        document.getElementById("anotherUserFirstNameInput").placeholder;

      this.editLastName =
        this.editLastName ||
        document.getElementById("anotherUserLastNameInput").placeholder;

      this.editOrganization =
        this.editOrganization || this.selectedOrganization;

      if (this.editPassword == this.editPasswordAgain) {
        await dp.editUserByEmail(
          this.editPassword,
          this.editEmail,
          this.plusPhone + this.selectedNum + this.editPhoneNum,
          this.editRole,
          this.editFirstName,
          this.editLastName,
          this.editOrganization
        );
        this.userArray = await dp.getUserListNotDeleted();
        this.phoneNumPlaceholder = await this.selectedUser.phone_num.slice(
          5,
          12
        );
        this.phoneselectedPlaceholder = await this.selectedUser.phone_num.slice(
          3,
          5
        );
        this.editUsername = undefined;
        this.editPassword = undefined;
        this.editPasswordAgain = undefined;
        this.editEmail = undefined;
        this.editPhoneNum = undefined;
        this.editFirstName = undefined;
        this.editLastName = undefined;
        this.editOrganization = undefined;

        //alert("Editing was successfull!");
      } else alert("Passwords doesn't match!");
    },
    //-----------------------------------------------------
    async changeRole(role, item) {
      await dp.editUserByEmail(
        undefined,
        item.email,
        item.phoneNum,
        role,
        item.lastName,
        item.firstName,
        item.organization
      );
      this.userArray = await dp.getUserListNotDeleted();
    },
    async basket() {
      localStorage.setItem("droneArray", JSON.stringify(this.droneBasketArray));
      localStorage.setItem(
        "batteryArray",
        JSON.stringify(this.batteryBasketArray)
      );
      this.$router.push("/basket");
    },
    async openUserGuide() {
      if (
        this.currentUser.role == "admin" ||
        this.currentUser.role == "semiAdmin" ||
        this.currentUser.role == "developer"
      ) {
        window.open(
          this.apiUrl + "/sample/" + "bacc5844-9669-46bd-8360-fdbff5b5c007"
        );
      } else {
        window.open(
          this.apiUrl + "/sample/" + "bacc5844-9669-46bd-8360-fdbff5b5c521"
        );
      }
    },
    async test() {
      window.open(
        this.apiUrl + "/drone_pdf/" + "f9511954-6ade-47ba-af15-e279b7ac2147"
      );
    },

    /* ----------------------------DELETE USER--------------------------------- */
    async deleteUser(item, index) {
      if (confirm("Are you sure you want to DELETE this user?")) {
        this.pilotUser = await dp.getPilotByUserUuid(item.uuid);
        this.pilotDelete = [];
        this.userDelete = [];
        if (this.pilotUser) {
          this.pilotDelete = await dp.getReservedDronesByPilotId(
            this.pilotUser.licence_id
          );
          this.userDelete = await dp.getReservedDronesByUserId(
            this.pilotUser.user_uuid
          );
        } else {
          this.userDelete = await dp.getReservedDronesByUserId(item.uuid);
          this.pilotDelete = [];
        }

        console.log(
          this.pilotUser,
          this.userDelete.length,
          this.pilotDelete.length,
          index
        );
        if (this.pilotUser) {
          //User has pilot added to it
          if (
            (this.userDelete.length >= 1 && this.pilotDelete.length >= 1) ||
            (this.userDelete.length >= 1 && this.pilotDelete.length == 0) ||
            (this.userDelete.length == 0 && this.pilotDelete.length >= 1)
          ) {
            // 1 1
            if (confirm("This user and pilot is connected to an event!")) {
              await dp.softDeleteUserByEmail(item);
              await dp.softDeletePilotImagesByLicenceId(this.pilotUser);
              await dp.softDeletePilotById(this.pilotUser);
              this.userArray.splice(index, 1);
            }
          } else {
            // 0 0
            await dp.deletePilotImagesByLicenceId(this.pilotUser);
            await dp.deletePilotById(this.pilotUser);
            await dp.deleteUserByEmail(item);
            this.userArray.splice(index, 1);
          }
        } else {
          //User dont have pilot added to it
          if (this.userDelete.length >= 1) {
            // 1 1
            if (confirm("This user and pilot is connected to an event!")) {
              await dp.softDeleteUserByEmail(item);
              this.userArray.splice(index, 1);
            }
          } else {
            // 0 0
            await dp.deleteUserByEmail(item);
            this.userArray.splice(index, 1);
          }
        }
      }
      // this.userArray = await dp.getUserListNotDeleted();
    },

    /* ------------CREATE DDRONE------------------------------ */
    async createNewDrone() {
      let validateDroneName = (droneName) => {
        return String(droneName).match(/^\s*(?:\S\s*){4,21}$/);
      };

      let droneNameBool = false;
      let droneTypeBool = false;
      let droneSerialNumBool = false;
      let droneManufacturerBool = false;
      let regNumBool = false;
      let startInsurBool = false;
      let endInsurBool = false;
      let droneInsurNumBool = false;
      let droneLabIdBool = false;
      let droneBatteryTypeBool = false;
      let droneBatteryConnectorBool = false;
      let reqBatteriesBool = false;
      let droneLocalBool = false;
      let createDroneBoughtBool = false;
      let droneImageUploadBoolArray = [];

      if (validateDroneName(document.getElementById("droneName").value)) {
        droneNameBool = true;
        document.getElementById("droneName").style.border =
          "medium solid #3f9140";
      } else {
        droneNameBool = false;
        document.getElementById("droneName").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneType").value) {
        droneTypeBool = true;
        document.getElementById("droneType").style.border =
          "medium solid #3f9140";
      } else {
        droneTypeBool = false;
        document.getElementById("droneType").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneSerialNum").value) {
        droneSerialNumBool = true;
        document.getElementById("droneSerialNum").style.border =
          "medium solid #3f9140";
      } else {
        droneSerialNumBool = false;
        document.getElementById("droneSerialNum").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneManufacturer").value) {
        droneManufacturerBool = true;
        document.getElementById("droneManufacturer").style.border =
          "medium solid #3f9140";
      } else {
        droneManufacturerBool = false;
        document.getElementById("droneManufacturer").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("regNum").value) {
        regNumBool = true;
        document.getElementById("regNum").style.border = "medium solid #3f9140";
      } else {
        regNumBool = false;
        document.getElementById("regNum").style.border = "medium solid #c9021d";
      }

      if (document.getElementById("startInsur").value) {
        startInsurBool = true;
        document.getElementById("startInsur").style.border =
          "medium solid #3f9140";
      } else {
        startInsurBool = false;
        document.getElementById("startInsur").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("endInsur").value) {
        endInsurBool = true;
        document.getElementById("endInsur").style.border =
          "medium solid #3f9140";
      } else {
        endInsurBool = false;
        document.getElementById("endInsur").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneInsurNum").value) {
        droneInsurNumBool = true;
        document.getElementById("droneInsurNum").style.border =
          "medium solid #3f9140";
      } else {
        droneInsurNumBool = false;
        document.getElementById("droneInsurNum").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneLabId").value) {
        droneLabIdBool = true;
        document.getElementById("droneLabId").style.border =
          "medium solid #3f9140";
      } else {
        droneLabIdBool = false;
        document.getElementById("droneLabId").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneBatteryType").value) {
        droneBatteryTypeBool = true;
        document.getElementById("droneBatteryType").style.border =
          "medium solid #3f9140";
      } else {
        droneBatteryTypeBool = false;
        document.getElementById("droneBatteryType").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneBatteryConnector").value) {
        droneBatteryConnectorBool = true;
        document.getElementById("droneBatteryConnector").style.border =
          "medium solid #3f9140";
      } else {
        droneBatteryConnectorBool = false;
        document.getElementById("droneBatteryConnector").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("reqBatteries").value) {
        reqBatteriesBool = true;
        document.getElementById("reqBatteries").style.border =
          "medium solid #3f9140";
      } else {
        reqBatteriesBool = false;
        document.getElementById("reqBatteries").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("droneLocal").value) {
        droneLocalBool = true;
        document.getElementById("droneLocal").style.border =
          "medium solid #3f9140";
      } else {
        droneLocalBool = false;
        document.getElementById("droneLocal").style.border =
          "medium solid #c9021d";
      }

      if (this.createDroneBought) {
        createDroneBoughtBool = true;
        document.getElementById("createDroneBoughtId").style.border =
          "medium solid #3f9140";
      } else {
        createDroneBoughtBool = false;
        document.getElementById("createDroneBoughtId").style.border =
          "medium solid #c9021d";
      }
      for (let index = 1; index < 8; index++) {
        if (document.getElementById("droneImageUpload" + index).files[0]) {
          if (
            document
              .getElementById("droneImageUpload" + index)
              .files[0].name.toLowerCase()
              .includes(".jpg") ||
            document
              .getElementById("droneImageUpload" + index)
              .files[0].name.toLowerCase()
              .includes(".png")
          ) {
            droneImageUploadBoolArray[index] = true;
            document.getElementById("droneImageUpload" + index).style.border =
              "medium solid #3f9140";
          } else {
            droneImageUploadBoolArray[index] = false;
            document.getElementById("droneImageUpload" + index).style.border =
              "medium solid #c9021d";
          }
        } else {
          droneImageUploadBoolArray[index] = false;
          document.getElementById("droneImageUpload" + index).style.border =
            "medium solid #c9021d";
        }
      }
      if (
        droneNameBool &&
        droneTypeBool &&
        droneSerialNumBool &&
        droneManufacturerBool &&
        regNumBool &&
        startInsurBool &&
        endInsurBool &&
        droneInsurNumBool &&
        droneLabIdBool &&
        droneBatteryTypeBool &&
        droneBatteryConnectorBool &&
        reqBatteriesBool &&
        droneLocalBool &&
        createDroneBoughtBool &&
        droneImageUploadBoolArray.every(Boolean)
      ) {
        let msg = await dp.addDrone(
          this.createDroneName,
          this.createDroneType,
          this.createDroneSerialNum,
          this.createDroneManufacturer,
          this.createDroneLabId.replace(/ /g, "_"),
          this.createDroneComment,
          this.createDroneBought,
          this.createDroneRegNum,
          this.createDroneLocal
        );
        if (msg.message != "Drone already exists with this Lab ID") {
          console.log(this.createDroneLabId);
          await dp.addDroneBattery(
            this.createDroneBatteryType,
            this.createDroneBatteryConnector,
            this.createReqBatteries,
            this.createDroneLabId.replace(/ /g, "_")
          );
          await dp.addDroneInsurance(
            this.createStartInsur,
            this.createEndInsur,
            this.createDroneInsurNum,
            this.createDroneLabId.replace(/ /g, "_")
          );
          console.log("uploading");
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload1").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload1").files[0]
          );
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload2").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload2").files[0]
          );
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload3").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload3").files[0]
          );
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload4").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload4").files[0]
          );
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload5").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload5").files[0]
          );
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload6").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload6").files[0]
          );
          await dp.uploadDroneImages(
            document.getElementById("droneImageUpload7").files[0].name,
            this.createDroneLabId.replace(/ /g, "_"),
            document.getElementById("droneImageUpload7").files[0]
          );
          console.log("uploaded");

          //alert(msg.message);
          this.droneArray = await dp.getDroneNotDeleted();
          this.createDroneName = undefined;
          this.createDroneType = undefined;
          this.createDroneSerialNum = undefined;
          this.createDroneManufacturer = undefined;
          this.createDroneLabId = undefined;
          this.createDroneComment = undefined;
          this.createDroneBought = undefined;
          this.createDroneRegNum = undefined;
          this.createDroneLocal = undefined;
          this.createDroneBatteryType = undefined;
          this.createDroneBatteryConnector = undefined;
          this.createReqBatteries = undefined;
          this.createStartInsur = undefined;
          this.createEndInsur = undefined;
          this.createDroneInsurNum = undefined;
          console.log("reset all");
          document.getElementById("successDrone").click();
          document.getElementById("cancelButtonCreateDrone").click();
        } else {
          //alert(msg.message);
          document.getElementById("droneLabId").style.border =
            "medium solid #c9021d";
        }
      } else {
        alert("Drone creation failed! Please fill all the fields!");
      }
    },

    /* ------------DDRONE SELECT------------------------------ */
    /* selectDrone(item) {
      this.selectedDrone = item;
    }, */
    /* ------------NEW BATTERY------------------------------ */
    async createNewBattery() {
      let validateBatteryName = (batteryName) => {
        return String(batteryName).match(/^\s*(?:\S\s*){4,21}$/);
      };

      let batteryCellNumBool = false;
      let batteryCapacityBool = false;
      let batteryManufacturerBool = false;
      let batteryTypeBool = false;
      let batteryLabIdBool = false;
      let batteryConnectorBool = false;
      let batteryNameBool = false;
      let TechnologyBool = false;
      let batteryCValueBool = false;
      let batteryLocalBool = false;
      let batteryImageUploadBoolArray = [];

      if (document.getElementById("batteryCellNum").value) {
        batteryCellNumBool = true;
        document.getElementById("batteryCellNum").style.border =
          "medium solid #3f9140";
      } else {
        batteryCellNumBool = false;
        document.getElementById("batteryCellNum").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("batteryCapacity").value) {
        batteryCapacityBool = true;
        document.getElementById("batteryCapacity").style.border =
          "medium solid #3f9140";
      } else {
        batteryCapacityBool = false;
        document.getElementById("batteryCapacity").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("batteryManufacturer").value) {
        batteryManufacturerBool = true;
        document.getElementById("batteryManufacturer").style.border =
          "medium solid #3f9140";
      } else {
        batteryManufacturerBool = false;
        document.getElementById("batteryManufacturer").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("batteryType").value) {
        batteryTypeBool = true;
        document.getElementById("batteryType").style.border =
          "medium solid #3f9140";
      } else {
        batteryTypeBool = false;
        document.getElementById("batteryType").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("batteryLabId").value) {
        batteryLabIdBool = true;
        document.getElementById("batteryLabId").style.border =
          "medium solid #3f9140";
      } else {
        batteryLabIdBool = false;
        document.getElementById("batteryLabId").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("batteryConnector").value) {
        batteryConnectorBool = true;
        document.getElementById("batteryConnector").style.border =
          "medium solid #3f9140";
      } else {
        batteryConnectorBool = false;
        document.getElementById("batteryConnector").style.border =
          "medium solid #c9021d";
      }

      if (validateBatteryName(document.getElementById("batteryName").value)) {
        batteryNameBool = true;
        document.getElementById("batteryName").style.border =
          "medium solid #3f9140";
      } else {
        batteryNameBool = false;
        document.getElementById("batteryName").style.border =
          "medium solid #c9021d";
      }
      if (this.createBatteryTechnology) {
        TechnologyBool = true;
        document.getElementById("batteryTechnology").style.border =
          "medium solid #3f9140";
      } else {
        TechnologyBool = false;
        document.getElementById("batteryTechnology").style.border =
          "medium solid #c9021d";
      }
      if (document.getElementById("batteryCValue").value) {
        batteryCValueBool = true;
        document.getElementById("batteryCValue").style.border =
          "medium solid #3f9140";
      } else {
        batteryCValueBool = false;
        document.getElementById("batteryCValue").style.border =
          "medium solid #c9021d";
      }
      if (document.getElementById("batteryLocal").value) {
        batteryLocalBool = true;
        document.getElementById("batteryLocal").style.border =
          "medium solid #3f9140";
      } else {
        batteryLocalBool = false;
        document.getElementById("batteryLocal").style.border =
          "medium solid #c9021d";
      }

      for (let index = 1; index < 3; index++) {
        if (document.getElementById("batteryImageUpload" + index).files[0]) {
          if (
            document
              .getElementById("batteryImageUpload" + index)
              .files[0].name.toLowerCase()
              .includes(".jpg") ||
            document
              .getElementById("batteryImageUpload" + index)
              .files[0].name.toLowerCase()
              .includes(".png")
          ) {
            batteryImageUploadBoolArray[index] = true;
            document.getElementById("batteryImageUpload" + index).style.border =
              "medium solid #3f9140";
          } else {
            batteryImageUploadBoolArray[index] = false;
            document.getElementById("batteryImageUpload" + index).style.border =
              "medium solid #c9021d";
          }
        } else {
          batteryImageUploadBoolArray[index] = false;
          document.getElementById("batteryImageUpload" + index).style.border =
            "medium solid #c9021d";
        }
      }
      if (
        batteryCellNumBool &&
        batteryCapacityBool &&
        batteryManufacturerBool &&
        batteryTypeBool &&
        batteryLabIdBool &&
        batteryConnectorBool &&
        batteryNameBool &&
        TechnologyBool &&
        batteryCValueBool &&
        batteryLocalBool &&
        batteryImageUploadBoolArray.every(Boolean)
      ) {
        try {
          let response = await dp.addBattery(
            this.createBatteryCellNum,
            this.createBatteryCapacity,
            this.createBatteryManufacturer,
            this.createBatteryType,
            this.createBatteryLabId.replace(/ /g, "_"),
            this.createBatteryConnector,
            this.createBatteryName,
            this.createBatteryTechnology,
            this.createBatteryLocal,
            this.createBatteryCValue
          );

          if (response.message != "Request failed with status code 500") {
            await dp.uploadBatteryImages(
              document.getElementById("batteryImageUpload1").files[0].name,
              this.createBatteryLabId.replace(/ /g, "_"),
              document.getElementById("batteryImageUpload1").files[0]
            );
            await dp.uploadBatteryImages(
              document.getElementById("batteryImageUpload2").files[0].name,
              this.createBatteryLabId.replace(/ /g, "_"),
              document.getElementById("batteryImageUpload2").files[0]
            );

            this.batteryArray = await dp.getBatteryNotDeleted();
            this.createBatteryCellNum = undefined;
            this.createBatteryCapacity = undefined;
            this.createBatteryManufacturer = undefined;
            this.createBatteryType = undefined;
            this.createBatteryLabId = undefined;
            this.createBatteryConnector = undefined;
            this.createBatteryName = undefined;
            this.createBatteryTechnology = undefined;
            this.createBatteryLocal = undefined;
            this.createBatteryCValue = undefined;
            //alert("Battery creation successful!");
            document.getElementById("successBattery").click();
            document.getElementById("cancelButtonBatteryCreate").click();
          } else {
            document.getElementById("batteryLabId").style.border =
              "medium solid #c9021d";
            alert("Battery with this lab ID already exists");
          }
        } catch (error) {
          alert(error);
        }
      } else {
        alert("Battery creation failed! Please fill all the fields!");
      }
    },

    /* ---------------------------MY-EVENTS SELECT----------------- */
    async selectEvent(item) {
      this.actualEvent = item;
      this.actualEventId = item.event_id;
      this.actualLabId = item.drone_lab_id;
    },

    /* ---------------------------IMAGE UPLOAD BEFORE---------------------- */

    async applyBeforeLab() {
      let beforeCheckLabBoolArray = [];

      for (let index = 1; index < 24; index++) {
        if (document.getElementById("check" + index).checked) {
          beforeCheckLabBoolArray[index] = true;
          document.getElementById("check" + index).style.border =
            "medium solid #3f9140";
        } else {
          beforeCheckLabBoolArray[index] = false;
          document.getElementById("check" + index).style.border =
            "medium solid #c9021d";
        }
      }
      if (beforeCheckLabBoolArray.every(Boolean)) {
        await dp.editReservedDroneById(
          this.actualEvent.id,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          undefined,
          undefined
        );
        this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
          this.currentUser.uuid
        );
        this.actualEvent.lab_check = true;

        document.getElementById("cancelButtonBeforeUploadLab").click();
      } else {
        alert(
          "Please make sure to check all the boxes if the drone is working properly, else the drone is not allowed to fly!"
        );
      }
    },

    async applyBeforeField() {
      let beforeCheckFieldBoolArray = [];

      for (let index = 24; index < 40; index++) {
        if (document.getElementById("check" + index).checked) {
          beforeCheckFieldBoolArray[index] = true;
          document.getElementById("check" + index).style.border =
            "medium solid #3f9140";
        } else {
          beforeCheckFieldBoolArray[index] = false;
          document.getElementById("check" + index).style.border =
            "medium solid #c9021d";
        }
      }
      if (beforeCheckFieldBoolArray.every(Boolean)) {
        await dp.editReservedDroneById(
          this.actualEvent.id,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          undefined
        );
        this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
          this.currentUser.uuid
        );
        this.actualEvent.field_check = true;

        document.getElementById("cancelButtonBeforeUploadField").click();
      } else {
        alert(
          "Please make sure to check all the boxes if the drone is working properly, else the drone is not allowed to fly!"
        );
      }
    },
    /* ---------------------------IMAGE UPLOAD AFTER---------------------- */
    async applyAfterUpload() {
      let imageUploadAfterDoneBool = true;
      let logFliesUploadAfterBool = true;
      let pdfUploadAfterBool = true;
      let images = document.getElementById("imageUploadAfterDone").files;
      for (let index = 0; index < images.length; index++) {
        if (
          !document
            .getElementById("imageUploadAfterDone")
            .files[index].name.toLowerCase()
            .includes(".jpg") &&
          !document
            .getElementById("imageUploadAfterDone")
            .files[index].name.toLowerCase()
            .includes(".png")
        ) {
          imageUploadAfterDoneBool = false;
          document.getElementById("imageUploadAfterDone").style.border =
            "medium solid #c9021d";
        }
      }
      if (imageUploadAfterDoneBool == true) {
        document.getElementById("imageUploadAfterDone").style.border =
          "medium solid #3f9140";
      }
      if (
        !document
          .getElementById("pdfUploadAfter")
          .files[0].name.toLowerCase()
          .includes(".pdf")
      ) {
        pdfUploadAfterBool = false;
        document.getElementById("pdfUploadAfter").style.border =
          "medium solid #c9021d";
      } else {
        document.getElementById("pdfUploadAfter").style.border =
          "medium solid #3f9140";
      }
      if (
        !document
          .getElementById("logFliesUploadAfter")
          .files[0].name.toLowerCase()
          .includes(".txt") &&
        !document
          .getElementById("logFliesUploadAfter")
          .files[0].name.toLowerCase()
          .includes(".rlog") &&
        !document
          .getElementById("logFliesUploadAfter")
          .files[0].name.toLowerCase()
          .includes(".tlog")
      ) {
        logFliesUploadAfterBool = false;
        document.getElementById("logFliesUploadAfter").style.border =
          "medium solid #c9021d";
      } else {
        document.getElementById("logFliesUploadAfter").style.border =
          "medium solid #3f9140";
      }

      if (
        pdfUploadAfterBool &&
        imageUploadAfterDoneBool &&
        logFliesUploadAfterBool
      ) {
        //-----------------LOGS UPLOAD----------------------------
        let logs = document.getElementById("logFliesUploadAfter").files;
        for (let index = 0; index < logs.length; index++) {
          await dp.uploadDroneLog(
            logs[index].name,
            this.actualLabId,
            logs[index],
            this.actualEventId
          );
        }

        //-----------------PDF UPLOAD----------------------------
        let pdfs = document.getElementById("pdfUploadAfter").files[0];
        await dp.uploadDronePdf(
          document.getElementById("pdfUploadAfter").files[0].name,
          this.actualLabId,
          pdfs,
          this.actualEventId
        );
        //-----------------IMAGES UPLOAD-------------------------

        for (let index = 0; index < images.length; index++) {
          await dp.uploadDroneImagesAfterFlight(
            document.getElementById("imageUploadAfterDone").files[index].name,
            this.actualLabId,
            document.getElementById("imageUploadAfterDone").files[index],
            this.actualEventId
          );
        }
        await dp.editReservedDroneById(
          this.actualEvent.id,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          undefined,
          undefined,
          undefined
        );
        this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
          this.currentUser.uuid
        );
        this.actualEvent.after_check = true;

        document.getElementById("cancelButtonAfterUpload").click();
        return true;
      } else {
        alert("Please upload all files before push apply!");
        return false;
      }
    },
    pdfUploadAfter() {
      this.pdfUploadAfterDone = true;
    },
    imageUploadAfter() {
      this.imageUploadAfterDone = true;
    },

    logUploadAfter() {
      this.logUploadAfterDone = true;
    },

    /* ---------------------CALENDAR------------------------------ */
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends;
    },
    async handleEventClick(clickInfo) {
      this.clickedEvent = await dp.getEventById(clickInfo.event.id);

      let reserved_drones = await dp.getReservedDronesByEventId(
        clickInfo.event.id
      );

      this.eventUser = await dp.getUserByUuid(reserved_drones[0].user_uuid);

      let reservedBatteries = await dp.getReservedBatteriesByEventId(
        clickInfo.event.id
      );
      this.clickedEventPilot = [];
      let drones = [];
      let pilots = [];
      for (let element of reserved_drones) {
        drones.push(element.drone_lab_id);
        for (
          let pilotIndex = 0;
          pilotIndex < element.pilot_id.length;
          pilotIndex++
        ) {
          pilots.push(element.pilot_id[pilotIndex]);
        }
      }
      let pilotIndex = 0;

      for await (let pilot of pilots) {
        pilots[pilotIndex] = await dp.getPilotById(pilot);

        pilots[pilotIndex] = await dp.getUserByUuid(
          pilots[pilotIndex].user_uuid
        );

        this.clickedEventPilot.push(pilots[pilotIndex].email);

        pilotIndex++;
      }

      let batteries = [];
      for (let element of reservedBatteries) {
        batteries.push(element.battery_lab_id);
      }
      if (this.clickedEvent.request_status == "#6e282f") {
        this.requestStatus = "Not accepted yet";
      } else {
        this.requestStatus = "Accepted";
      }
      this.clickedEventDrone = [...drones].join(" , ");
      this.clickedEventBattery = [...batteries].join(" , ");
      this.clickedEventPilot = [...this.clickedEventPilot].join(" , ");

      document.getElementById("eventModalButton").click();
    },
    handleEvents(/* events */) {},
    getEvents() {
      console.log("getEvents fired");
    },
    /* ---------------------CALENDAR END------------------------------ */

    /* ------------------------LIST VIEW------------------------- */
    buttonPush() {
      this.buttonPushed = !this.buttonPushed;
    },
    /* ------------------------REQUESTS------------------------- */

    async jumpToRequestModal(item, index) {
      let Events = await dp.getEventByDate(item.date_start, item.date_end);

      //---------------------Pilot Stuff----------------------------------------------
      this.pilotArray = await dp.getPilotNotDeleted();
      this.reservedPilots = [];

      //--------------------Battery stuff---------------------------------------------------

      this.batteryArray = await dp.getBatteryNotDeleted();

      //------------------------get reserved batteries/Pilots to know how many are occupied---------------
      this.reservedBatteries = [];
      for (const event of Events) {
        //------------------------Getting Reserved Pilots----------------
        let reservedDrones = await dp.getReservedDronesByEventId(
          event.event_id
        );
        /* console.log("reservedDrones", reservedDrones);
        console.log("reservedDrones", this.pilotArray); */

        for (const drones of reservedDrones) {
          for (let index = 0; index < drones.pilot_id.length; index++) {
            let foundIndex = this.pilotArray.findIndex(
              (obj) => obj.licence_id == drones.pilot_id[index]
            );
            //console.log("foundIndex", foundIndex);
            if (foundIndex != -1) {
              this.pilotArray.splice(foundIndex, 1);
            }
          }
        }

        //------------------------Getting Reserved Pilots End----------------
        let reservedBatteryNum = await dp.getReservedBatteriesByEventId(
          event.event_id
        );
        if (reservedBatteryNum.length > 0) {
          this.reservedBatteries.push(
            await dp.getReservedBatteriesByEventId(event.event_id)
          );
        }
      }

      for (
        let batteryIndex = this.batteryArray.length - 1;
        batteryIndex >= 0;
        batteryIndex--
      ) {
        let foundIndex = -1;
        for (const event of this.reservedBatteries) {
          foundIndex = event.findIndex(
            (obj) =>
              obj.battery_lab_id == this.batteryArray[batteryIndex].lab_id
          );
        }
        if (foundIndex != -1) {
          this.batteryArray.splice(batteryIndex, 1);
        }
      }
      this.selectedEvent = item;
      this.selectedEvent.index = index;
      this.reservedDroneArray = {
        drones: [],
        droneBatteries: [],
      };
      this.reqiredBatteries = [];
      this.reqiredPilots = [];
      this.actualPilots = [];
      this.poppedPilots = [];

      let reservedDrones = await dp.getReservedDronesByEventId(item.event_id);
      for (item of reservedDrones) {
        this.reservedDroneArray.droneBatteries.push(
          await dp.getDroneBatteryById(item.drone_lab_id)
        );
        this.reqiredPilots.push([]);
        this.poppedPilots.push([]);
        this.actualPilots.push([]);
        this.reqiredBatteries.push([]);
        this.popped.push([]);
        this.actualInputArray.push([]);
        this.reservedDroneArray.drones.push(item);
      }
      this.batteryArrayLength = this.batteryArray.length;
      this.batteryArrayUnpoped = [...this.batteryArray];
      this.pilotArrayUnpoped = [...this.pilotArray];
      this.pilotArrayLength = this.pilotArray.length;
    },

    async pilotSelect(index, item, droneIndex) {
      console.log(index, droneIndex, this.actualPilots);
      let user = await dp.getUserByEmail(item);
      let pilot = await dp.getPilotByUserUuid(user.uuid);
      let foundIndex = this.pilotArray.findIndex(
        (obj) => obj.licence_id == pilot.licence_id
      );
      if (foundIndex == -1) {
        ///------------------if it's not found in pilotArray--------------------
        document.getElementById(
          "requiredPilotSearch" + droneIndex + index
        ).style =
          "border:medium solid #c9021d;color:#000; background-color:#fff; width:250px";
        this.actualPilots[droneIndex][index] = pilot.licence_id;
        let inPilotArrayUnpoped = this.pilotArrayUnpoped.findIndex(
          (obj) => obj.licence_id == pilot.licence_id
        );
        if (
          inPilotArrayUnpoped == -1 &&
          this.poppedPilots[droneIndex][index] != undefined
        ) {
          ///------------------if it's not found in batteryArrayUnpopped--------------------
          let push = this.pilotArray.findIndex(
            (obj) =>
              obj.licence_id == this.poppedPilots[droneIndex][index].licence_id
          );

          if (push == -1) {
            ///------------------if it' not found in batteryArray so it's okaytopush it back to it--------------------------
            this.pilotArray.push(this.poppedPilots[droneIndex][index]);
          }
        } else if (inPilotArrayUnpoped >= 0) {
          ///------------------if it's found in batteryArrayUnpopped that means it's taken already--------------------
          alert("battery is already taken");
          this.actualPilots[droneIndex][index] =
            this.pilotArrayUnpoped[inPilotArrayUnpoped];
        }
      } else {
        ///------------------if it's found in pilotArray--------------------
        if (
          this.actualPilots[droneIndex][index] ==
            this.poppedPilots[droneIndex][index] &&
          this.actualPilots[droneIndex][index] != undefined
        ) {
          this.pilotArray.push(this.actualPilots[droneIndex][index]);
        }
        this.poppedPilots[droneIndex][index] = this.pilotArray[foundIndex];
        this.actualPilots[droneIndex][index] = this.pilotArray[foundIndex];
        this.pilotArray.splice(foundIndex, 1);
        document.getElementById(
          "requiredPilotSearch" + droneIndex + index
        ).style =
          "border:medium solid #3f9140; color:#000; background-color:#fff; width:250px";
      }
    },
    batterySelect(batteryName, droneIndex, batteryIndex) {
      let foundIndex = this.batteryArray.findIndex(
        (obj) => obj.lab_id == batteryName
      );
      if (foundIndex == -1) {
        ///------------------if it's not found in batteryArray--------------------
        document.getElementById(
          "requiredBatteriesSearch" + droneIndex + batteryIndex
        ).style =
          "border:medium solid #c9021d; color:#000; background-color:#fff; width:250px";
        this.actualInputArray[droneIndex][batteryIndex] = batteryName;
        let inbatteryArrayUnpoped = this.batteryArrayUnpoped.findIndex(
          (obj) => obj.lab_id == batteryName
        );
        if (
          inbatteryArrayUnpoped == -1 &&
          this.popped[droneIndex][batteryIndex] != undefined
        ) {
          ///------------------if it's not found in batteryArrayUnpopped--------------------
          let push = this.batteryArray.findIndex(
            (obj) => obj.lab_id == this.popped[droneIndex][batteryIndex].lab_id
          );
          if (push == -1) {
            ///------------------if it' not found in batteryArray so it's okaytopush it back to it--------------------------
            this.batteryArray.push(this.popped[droneIndex][batteryIndex]);
          }
        } else if (inbatteryArrayUnpoped >= 0) {
          ///------------------if it's found in batteryArrayUnpopped that means it's taken already--------------------
          alert("battery is already taken");
          this.actualInputArray[droneIndex][batteryIndex] =
            this.batteryArrayUnpoped[inbatteryArrayUnpoped];
        }
      } else {
        ///------------------if it's found in batteryArray--------------------
        if (
          this.actualInputArray[droneIndex][batteryIndex] ==
            this.popped[droneIndex][batteryIndex] &&
          this.actualInputArray[droneIndex][batteryIndex] != undefined
        ) {
          this.batteryArray.push(
            this.actualInputArray[droneIndex][batteryIndex]
          );
        }
        this.popped[droneIndex][batteryIndex] = this.batteryArray[foundIndex];
        this.actualInputArray[droneIndex][batteryIndex] =
          this.batteryArray[foundIndex];
        this.batteryArray.splice(foundIndex, 1);
        document.getElementById(
          "requiredBatteriesSearch" + droneIndex + batteryIndex
        ).style =
          "border:medium solid #3f9140; color:#000; background-color:#fff; width:250px";
      }
    },

    async acceptRequest(item, index) {
      console.log(this.pilotArray);
      let required = 0;
      let picked = 0;
      let pilotPicked = 0;
      let pilotRequired = 0;

      //---Counting batteries---
      for (let dronebattery of this.reservedDroneArray.droneBatteries) {
        required += dronebattery.required_batteries;
      }
      for (
        let index = 0;
        index < this.reservedDroneArray.droneBatteries.length;
        index++
      ) {
        for (
          let battery = 0;
          battery <
          this.reservedDroneArray.droneBatteries[index].required_batteries;
          battery++
        ) {
          if (this.reqiredBatteries[index][battery] != undefined) {
            picked++;
          } else {
            document.getElementById(
              "requiredBatteriesSearch" + index + battery
            ).style =
              "border:medium solid #c9021d; color:#000; background-color:#fff; width:250px";
          }
        }
      }
      //---Counting pilots---

      for (let drone of this.reservedDroneArray.drones) {
        for (let index = 0; index < drone.pilot_id.length; index++) {
          pilotRequired++;
        }
      }
      for (
        let index = 0;
        index < this.reservedDroneArray.drones.length;
        index++
      ) {
        for (
          let pilotindex = 0;
          pilotindex < this.reservedDroneArray.drones[index].pilot_id.length;
          pilotindex++
        ) {
          if (
            this.reservedDroneArray.drones[index].pilot_id[pilotindex] ==
            "neededNo"
          ) {
            this.reqiredPilots[index][pilotindex] = "neededNo";
            this.actualPilots[index][pilotindex] = { licence_id: "neededNo" };
            pilotPicked++;
          } else if (this.reqiredPilots[index][pilotindex] != undefined) {
            pilotPicked++;
          } else {
            document.getElementById(
              "requiredPilotSearch" + index + pilotindex
            ).style =
              "border:medium solid #c9021d; color:#000; background-color:#fff; width:250px";
          }
        }
      }
      let wrong = false;
      console.log(this.actualPilots);
      for (const pilots of this.actualPilots) {
        for (const pilot of pilots) {
          if (pilot) {
            let test = await dp.getPilotById(pilot.licence_id);
            if (!test) {
              wrong = true;
            }
          } else {
            wrong = true;
          }
        }
      }
      for (const drone of this.actualInputArray) {
        for (const battery of drone) {
          let test = await dp.getBatteryById(battery.lab_id);
          if (!test) {
            wrong = true;
          }
        }
      }
      console.log("REQUIRED PILOTS", pilotRequired);
      console.log("PICKED PILOTS", pilotPicked);
      console.log("actual pilots", this.actualPilots);
      let licenceArray = [];
      for (let index = 0; index < this.actualPilots.length; index++) {
        licenceArray.push([]);
        for (
          let pilotIndex = 0;
          pilotIndex < this.actualPilots[index].length;
          pilotIndex++
        ) {
          licenceArray[index].push(
            this.actualPilots[index][pilotIndex].licence_id
          );
        }
      }
      //console.log(licenceArray)
      console.log(item, index, wrong);
      if (required == picked && pilotPicked == pilotRequired) {
        if (!wrong) {
          let reservedDrones = await dp.getReservedDronesByEventId(
            item.event_id
          );
          let pilotIndex = 0;
          for (const drones of reservedDrones) {
            if (this.actualPilots[pilotIndex]) {
              await dp.editReservedDroneById(
                drones.id,
                drones.event_id,
                licenceArray[pilotIndex],
                drones.user_uuid,
                drones.drone_lab_id,
                false,
                false,
                false,
                undefined
              );
            }
            ////---------------ide kell-e hogy a maintanance az pilot id -t tartalmazzon azt aki repólt vele?
            pilotIndex++;
          }
          reservedDrones = await dp.getReservedDronesByEventId(item.event_id);
          let droneIndex = 0;
          for (const batteryArray of this.reqiredBatteries) {
            for (const battery_lab_id of batteryArray) {
              await dp.addBatteryToEvent(
                item.event_id,
                reservedDrones[droneIndex].user_uuid,
                reservedDrones[droneIndex].pilot_id,
                battery_lab_id
              );
              await dp.addBatteryToEvent(
                item.event_id + 1,
                reservedDrones[droneIndex].user_uuid,
                reservedDrones[droneIndex].pilot_id,
                battery_lab_id
              );
            }

            droneIndex++;
          }
          this.sendMail(reservedDrones, "accepted", item);

          this.requests.splice(index, 1);

          let event = await dp.getEventById(item.event_id);
          console.log(event);

          await dp.editEvent(
            item.event_id,
            item.event_name,
            undefined,
            undefined,
            "#404C2B"
          );

          for await (let events of this.calendarOptions.events) {
            if (events.event_id == item.event_id) {
              events.backgroundColor = "#404C2B";
            }
          }
          let events = await dp.getEventsNotDeleted();
          this.calendarOptions.events = [];
          for await (let element of events) {
            this.calendarOptions.events.push({
              title: element.event_name,
              start: element.date_start,
              end: element.date_end,
              backgroundColor: element.request_status,
              id: element.event_id,
            });
          }
          this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
            this.currentUser.uuid
          );
          document.getElementById("cancelButtonRequestsModal").click();
        } else {
          alert("Please select valid batteries and pilots.");
        }
      } else {
        alert("Please select every component for the request.");
      }
    },

    async declineRequest(item, index) {
      for (let index = 0; index < this.calendarOptions.events.length; index++) {
        const element = this.calendarOptions.events[index];
        if (element.id == item.event_id) {
          this.calendarOptions.events.splice(index, 1);
          index = this.calendarOptions.events.length;
        }
      }
      await new Promise((resolve) => {
        resolve(this.sendMail(undefined, "rejected", item));
      });
      console.log("delete");
      await dp.deleteReservedDroneByEventId(item.event_id);
      await dp.deleteReservedDroneByEventId(item.event_id + 1);
      await dp.deleteEventById(item.event_id);
      await dp.deleteEventById(item.event_id + 1);

      this.requests.splice(index, 1);
      let events = await dp.getEventsNotDeleted();

      this.calendarOptions.events = [];
      for await (let element of events) {
        this.calendarOptions.events.push({
          title: element.event_name,
          start: element.date_start,
          end: element.date_end,
          backgroundColor: element.request_status,
          id: element.event_id,
        });
      }
      this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
        this.currentUser.uuid
      );
    },

    /* -----------------------------CREATE PILOT---------------------------- */
    async createNewPilot() {
      let createLicenceIdBool = false;
      let createLicenceLevelBool = false;
      let userSearchBool = false;
      let imagebool = false;

      let validateUser = async (email) => {
        let user = await dp.getUserByEmail(email);
        if (typeof user == "object") {
          return true;
        } else {
          return false;
        }
      };
      if (document.getElementById("licenceId").value) {
        createLicenceIdBool = true;
        document.getElementById("licenceId").style.border =
          "medium solid #3f9140";
      } else {
        createLicenceIdBool = false;
        document.getElementById("licenceId").style.border =
          "medium solid #c9021d";
      }

      if (this.createLicenceLevel) {
        createLicenceLevelBool = true;
        document.getElementById("createLicenceLevelId").style.border =
          "medium solid #3f9140";
      } else {
        createLicenceLevelBool = false;
        document.getElementById("createLicenceLevelId").style.border =
          "medium solid #c9021d";
      }
      if (await validateUser(this.pilotToUser)) {
        userSearchBool = true;
        document.getElementById("userSearch").style.border =
          "medium solid #3f9140";
      } else {
        userSearchBool = false;
        document.getElementById("userSearch").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("pilotImageUpload").files[0]) {
        if (
          document
            .getElementById("pilotImageUpload")
            .files[0].name.toLowerCase()
            .includes(".jpg") ||
          document
            .getElementById("pilotImageUpload")
            .files[0].name.toLowerCase()
            .includes(".png")
        ) {
          imagebool = true;
          document.getElementById("pilotImageUpload").style.border =
            "medium solid #3f9140";
        } else {
          imagebool = false;
          document.getElementById("pilotImageUpload").style.border =
            "medium solid #c9021d";
        }
      } else {
        imagebool = false;
        document.getElementById("pilotImageUpload").style.border =
          "medium solid #c9021d";
      }

      if (
        createLicenceIdBool &&
        createLicenceLevelBool &&
        userSearchBool &&
        imagebool
      ) {
        let user = await dp.getUserByEmail(this.pilotToUser);
        let reply = await dp.addPilot(
          this.createLicenceId.replace(/ /g, "_"),
          this.createLicenceLevel,
          user.uuid
        );

        if (!reply.message.includes("500")) {
          await dp.uploadPilotImages(
            document.getElementById("pilotImageUpload").files[0].name,
            this.createLicenceId.replace(/ /g, "_"),
            document.getElementById("pilotImageUpload").files[0]
          );
          document.getElementById("successPilot").click();
          document
            .getElementById("cancelButtonPilotCreateCalendarPage")
            .click();
          //this.pilotArray = await dp.getPilotNotDeleted();
          this.createLicenceId = undefined;
          this.createLicenceLevel = undefined;
          this.getAvailablePilots();
        } else {
          alert(
            "Something went wrong. Please try again and check if you are trying to add the Pilot to a valid User. If the User already has a pilot added to it's name, you can't add more."
          );
        }
      }
    },
    /* ------------LOGOUT------------------------------ */

    logout() {
      localStorage.removeItem("JWT");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("username");

      this.$router.push("/login");
    },

    /* ------------PILOT AVAILABILITY------------------------------ */

    async getAvailablePilots() {
      this.pilotArray = await dp.getPilotNotDeleted();
      this.pilotImageArray = await dp.getPilotImages();

      this.allPilotArray = [...this.pilotArray];
      this.availablePilots = [...this.pilotArray];
      this.notAvailablePilots = [];

      //let tzoffset = new Date().getTimezoneOffset() * 60000;
      let startDate = DateTime.now().toLocal().toUTC().toISO();
      let endDate = DateTime.fromISO(
        DateTime.now().toFormat("yyyy-mm-dd") + "T" + "23:59:00"
      )
        .toLocal()
        .toUTC()
        .toISO();
      let eventsToday = await dp.getEventByDate(startDate, endDate);
      let reservedDronesOnEvent;
      let reservedDrones = [];
      for (const events of eventsToday) {
        reservedDronesOnEvent = await dp.getReservedDronesByEventId(
          events.event_id
        );
        for (const drone of reservedDronesOnEvent) {
          reservedDrones.push(drone);
        }
      }

      for (const drones of reservedDrones) {
        for (let index = 0; index < drones.pilot_id.length; index++) {
          let foundIndex = this.allPilotArray.findIndex(
            (obj) => obj.licence_id == drones.pilot_id[index]
          );
          //console.log("foundIndex", foundIndex);
          if (foundIndex != -1) {
            this.notAvailablePilots.push(this.allPilotArray[foundIndex]);
            this.availablePilots.splice(foundIndex, 1);
            this.allPilotArray.splice(foundIndex, 1);
          }
        }
      }

      this.profilAvailable = [];
      this.profilNotAvailable = [];
      for (const element of this.availablePilots) {
        let images = await dp.getPilotImagesByLicenceId(element.licence_id);
        this.profilAvailable.push(images.uuid);
      }
      for (const element of this.notAvailablePilots) {
        let images = await dp.getPilotImagesByLicenceId(element.licence_id);
        this.profilNotAvailable.push(images.uuid);
      }
    },
  },
  async mounted() {
    console.log("Mounted");

    this.droneImageArray = await dp.getDroneImages();

    this.droneArray = await dp.getDroneNotDeleted();
    this.batteryArray = await dp.getBatteryNotDeleted();
    this.getAvailablePilots();

    this.requests = await dp.getRequestList();

    this.userArray = await dp.getUserListNotDeleted();
    this.currentUser = await dp.getUserByEmail(
      localStorage.getItem("userEmail")
    );
    this.phoneNumPlaceholder = await this.currentUser.phone_num.slice(5, 12);
    this.phoneselectedPlaceholder = await this.currentUser.phone_num.slice(
      3,
      5
    );
    this.myEvents = await dp.getEventsByUserUuidDateNotDelete(
      this.currentUser.uuid
    );

    //--------------------------------ADD EVENTS TO CALENDAR--------------------------------------------
    let events = await dp.getEventsNotDeleted();
    for (let element of events) {
      this.calendarOptions.events.push({
        title: element.event_name,
        start: element.date_start,
        end: element.date_end,
        backgroundColor: element.request_status,
        id: element.event_id,
      });
    }

    this.today = new Date();
  },
  data() {
    return {
      //-----------EVENT EDIT----------------------
      pilotArrayEventEdit: [],
      allPilotArrayEventEdit: [],
      availablePilotsEventEdit: [],
      availableBatteryArrayEventEdit: [],

      selectedDrone: [],
      avaliableDroneArray: [],
      eventEditPilotsArray: [],
      eventEditDronesArray: [],
      eventEditBatteriesArray: [],
      //---------Event edit end------------------------------------
      apiUrl: process.env.VUE_APP_API_URL || "http://localhost:5000",
      profilPicture: undefined,
      profilImage: [],
      pilotImageArray: [],
      profilAvailable: [],
      profilNotAvailable: [],
      actualEvent: {},
      actualLabId: undefined,
      actualEventId: undefined,

      eventStart: undefined,
      eventEndTime: undefined,
      eventEnd: undefined,
      eventStartTime: undefined,
      bugRepCommentVmod: undefined,
      developerEmail: [],

      today: undefined,
      applyBeforeUploadClick: false,
      applyAfterUploadClick: false,

      pdfUploadAfterDone: undefined,
      imageUploadAfterDone: undefined,
      logUploadAfterDone: undefined,

      //------------------------------------AVAILABLE PILOTS-----------------------------------
      availablePilots: [],
      allPilotArray: [],
      notAvailablePilots: [],

      //------------------------------------------------EVENT CLICKED---------------------------------
      clickedEvent: {
        event_name: undefined,
        date_start: undefined,
        date_end: undefined,
        reason: undefined,
      },

      pilotUser: undefined,
      pilotDelete: undefined,
      userDelete: undefined,

      eventUser: { first_name: undefined, last_name: undefined },
      clickedEventDrone: ["test"],
      clickedEventPilot: [],
      clickedEventBattery: ["test"],
      requestStatus: undefined,
      //------------------------------------REQUESTS PILOT-----------------------------------
      reservedPilots: [],
      reqiredPilots: [],
      poppedPilots: [],
      actualPilots: [],
      pilotArrayLength: 0,
      //------------------------------------REQUESTS BATTERY SHOW-----------------------------
      reservedDroneArray: {
        drones: [],
        droneBatteries: [],
      },
      reqiredBatteries: [],
      popped: [],
      actualInputArray: [],
      batteryArrayLength: 0,
      batteryArrayUnpoped: [],
      //----------------------------EDIT ANOTHER USER--------------

      selectedUser: {
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },
      //-------------------------------------MYEVENTS----------------------------------------
      myEvents: undefined,
      //--------------------------------------EVENT -------------------------------------
      selectedEvent: undefined,

      //-----------------------------------------IDK-------------------------
      selectedOrganization: undefined,
      plusPhone: "+36",
      selectedNum: undefined,
      selectedLevel: "Licence level",
      selectedOrg: "Organisation",
      selectedBought: "Bought",
      /* -----------------------USER DATA -------------------------------*/
      phoneNumPlaceholder: undefined,
      phoneselectedPlaceholder: undefined,
      user: localStorage.getItem("username"),
      currentUser: {
        firstName: undefined,
        lastName: undefined,
        userName: undefined,
        email: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },

      /* -----------------------RENTING DATA-------------------------------*/
      rider: undefined,
      riderLicenseNum: undefined,
      riderEmail: undefined,
      requester: undefined,
      requesterEmail: undefined,
      /* -----------------------CREATE DRONE-------------------------------*/
      createDroneName: undefined,
      createDroneType: undefined,
      createDroneSerialNum: undefined,
      createDroneManufacturer: undefined,
      createDroneRegNum: undefined,
      createStartInsur: undefined,
      createEndInsur: undefined,
      createDroneInsurNum: undefined,
      createDroneLabId: undefined,
      createDroneBatteryType: undefined,
      createDroneBatteryConnector: undefined,
      createReqBatteries: undefined,
      createDroneLocal: undefined,
      createDroneComment: undefined,
      createDroneBought: undefined,
      createDroneId: undefined,

      /* -----------------------  CREATE BATTERY -------------------------------*/
      createBatteryId: undefined,
      createBatteryLabId: undefined,
      createBatteryCellNum: undefined,
      createBatteryCapacity: undefined,
      createBatteryManufacturer: undefined,
      createBatteryName: undefined,
      createBatteryConnector: undefined,
      createBatteryTechnology: undefined,
      createBatteryCValue: undefined,
      createBatteryType: undefined,
      createBatteryLocal: undefined,

      /* -----------------------  EDIT USER -------------------------------*/
      editUsername: undefined,
      editPassword: undefined,
      editPasswordAgain: undefined,
      editEmail: undefined,
      editPhoneNum: undefined,
      editFirstName: undefined,
      editLastName: undefined,
      editOrganization: undefined,

      /* ---------------------------------CREATE PILOT------------------------------------- */
      createLicenceId: undefined,
      createLicenceLevel: undefined,
      pilotToUser: undefined,

      selectedPilot: {
        firstname: undefined,
        lastname: undefined,
        licence_id: undefined,
        licence_level: undefined,
        email: undefined,
        phone_num: undefined,
        organization: undefined,
      },
      username: localStorage.getItem("username"),

      /* -----------REQUESTS------------- */
      buttonPushed: false,

      /*-----------------BASKET--------------*/
      batteryBasketArray: [],
      droneBasketArray: [],
      droneImageArray: [],
      /* -----CALENDAR------ */
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        initialEvents: "events",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        events: [],
      },

      id: 0,
      /* ---------CALENDAR END----- */
      events: [],
      droneArray: [],
      batteryArray: [],
      pilotArray: [],
      requests: [],
      userArray: [],
    };
  },
};
</script>
<style>
#myeventsUploadModalTable td {
  vertical-align: middle;
  text-align: center;
}
#myeventsUploadModalTable {
  color: #fff;
}
.modalContentDark {
  background-color: #212529;
}
#editPhoneNumSelectInput {
  max-width: 55px;
}
.requiredPilotSearchClass {
  background-color: #fff;
  color: #000;
  width: 250px;
}
#weatherId {
  width: 100%;
  height: 360px;
  margin-top: -10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#requestTableDiv {
  display: flex;
  width: 100%;
}
#weatherLink {
  font-size: 12px;
  margin-top: -5px;
}
#plusPhone {
  width: 55px;
}
#successDrone {
  display: none;
}
#successDroneModal {
  color: #fff;
}
#allUsersTable {
  font-size: 14px;
}
#phoneTwo {
  width: 45px;
}
#plusPhoneAnother {
  width: 55px;
}
#successBattery {
  display: none;
}
#successBatteryModal {
  color: #fff;
}
.modal80Large {
  min-width: 80%;
}
.modal60Large {
  min-width: 60%;
}
#logFliesUploadAfter {
  width: 100%;
}
#newDroneTableId {
  color: white;
  text-align: left;
  width: 100%;
}
#pdfUploadAfter {
  width: 100%;
}
#imageUploadAfterDone {
  width: 100%;
}
#afterFlightTable {
  color: white;
  text-align: left;
  width: 100%;
  float: left;
}
#beforeCheckTableId {
  color: #fff;
}
#beforeCheckTable2Id {
  color: #fff;
}
.beforeCheckTable {
  color: #fff;
}
#eventModalButton {
  display: none;
}
#successPilot {
  display: none;
}
#successPilotModal {
  color: #fff;
}
.modalFooterCalendar {
  margin-bottom: 10px;
}
#editUser {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  font-size: 14px;
  border-radius: 12px;
  height: 40px;
}
#editUser:hover {
  background-color: rgb(99, 116, 69);
}
.dropdown-item {
  cursor: pointer;
}
#bugRepComment {
  width: 100%;
}
#myEventsBody {
  overflow: auto;
  max-height: 400px;
}
.uploadSucc {
  font-size: 18px;
}
.fc-theme-standard .fc-popover {
  background-color: #212529;
}
#requests-table {
  text-align: center;
  vertical-align: center;
  width: auto;
  min-width: 100%;
  margin-top: 5px;
  color: #fff;
  overflow-y: scroll;
  font-size: 14px;
}
#requests-table td {
  vertical-align: center;
  color: #fff;
}
#acceptButton {
  font-size: 14px;
}
#declineButton {
  font-size: 14px;
}
.fa-circle-info {
  color: #6d6875;
}
.beforeCheckTable td {
  max-width: 100px;
}

.newDroneTable td {
  border-color: transparent;
}
.beforeAfterButtons {
  text-align: center;
}
#beforeFlightLab {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
  margin-right: 10px;
}
#beforeFlightLab:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#beforeFlightField {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
  margin-right: 10px;
}
#beforeFlightField:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#beforeUploadButton {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
}
#beforeUploadButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#afterUploadButton {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
}
#afterUploadButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#fromDDCHandoverContract {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
}
#fromDDCHandoverContract:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#toDDCHandoverContract {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
}
#toDDCHandoverContract:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#fromBottomDDCHandoverContract {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
}
#fromBottomDDCHandoverContract:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#toBottomDDCHandoverContract {
  color: #fff;
  background-color: #6d6875;
  border-color: #6d6875;
}
#toBottomDDCHandoverContract:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
.checkListEndTable td {
  border-top: 0px solid #dee2e6;
  border-bottom: 0px solid #dee2e6;
  border-left: 0px solid #dee2e6;
  border-right: 0px solid #dee2e6;
}

.requestsTable {
  color: #fff;
}
#pwEye {
  bottom: 0px;
}
#pwSlashedEye {
  display: none;
  bottom: 0px;
}
#pwAgainEye {
  bottom: 0px;

  position: relative;
}
#pwAgainSlashedEye {
  display: none;
  bottom: 0px;

  position: relative;
}

#eyeIcon {
  bottom: 0px;
  position: relative;
}

#slashedEye {
  display: none;
  bottom: 0px;
  position: relative;
}
#showPasswordButton {
  position: absolute;
  left: 355px;
  bottom: 245px;
}
#showPasswordAgainButton {
  position: absolute;
  left: 355px;
  bottom: 190px;
}
.eyeButtonCalendar {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: black;
  position: relative;
  bottom: 28px;
  float: right;
  z-index: 2;
  margin: 2px;
  min-height: 24px;
  min-width: 20px;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #59555e;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  color: #fff;
}
.fc-daygrid-dot-event .fc-event-title {
  color: #fff;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#eventModalEdit {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  right: 175px;
  position: relative;
  float: left;
}
#eventModalEdit:hover {
  background-color: rgb(99, 116, 69);
}
#eventModalDelete {
  left: -310px;
  color: #fff;
  margin-left: 0px;
  position: relative;
  float: left;
  width: auto;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#eventModalDelete:hover {
  background-color: rgb(144, 55, 64);
}
.calendarEventTable {
  color: #fff;
}
.tdLeft {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
}
.tdRight {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  float: right;
}

#phoneNumInput {
  max-width: 90px;
}

.anotherModal {
  background-color: #212529;
}
.btn-close-white {
  color: #fff;
  opacity: 1;
}
#anotherUserPhoneNumInput {
  width: 85px;
}
.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}

#acceptRequestButton {
  margin-right: 20px;
  position: relative;
  float: right;
  width: auto;
  right: 30px;
}

#declineRequestButton {
  float: right;
  right: 0;
  width: auto;
  position: absolute;
}

.calendarPageDropdownMenu {
  max-height: 150px;
  overflow-y: auto;
  width: 10%;
}

.phoneNumDropdownMenu {
  width: 15%;
  max-width: 15%;
  min-width: 15%;
  max-height: 150px;
  overflow-y: auto;
}
.phoneNumDropdownItem {
  padding-left: 10px;
}
#createPilotPhoneNumber {
  width: 90px;
}
.modal-footer {
  height: 60px;
}

#my-events {
  position: relative;
  margin: auto;
  display: block;
  top: 5vh;
  margin-bottom: 10px;
  background-color: #212529;
  border: 5px solid #212529;
  color: #fff;
  border-radius: 12px;
  max-width: 87%;
  right: 15px;
}

#first-row {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
#pilotCards {
  width: auto;
  background-color: transparent;
  border-radius: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.pilotCardGroup {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  border-left: 0;
  flex-flow: row wrap;
  -ms-flex-item-align: center;
}
#pilot-img {
  margin: auto;
  padding-top: 5px;
}
#pilot-stats {
  position: relative;
  top: 6.5vh;
  overflow-y: auto;
  overflow-x: auto;
  margin: auto;
  display: block;
  background-color: #212529;
  border: 5px solid #212529;
  color: #fff;
  border-radius: 12px;
  max-width: 87%;
  right: 15px;
}
.requestsCard {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
#pilot-img {
  border-radius: 50%;
  width: 240px;
  height: 240px;
  object-fit: cover;
}

#pilot-img-busy {
  border-radius: 50%;
  width: 240px;
  height: 210px;
}

#pilot-card {
  border: 0;
  justify-content: center;
  display: flex;
  position: relative;
  background-color: transparent;
}

#avaible-text {
  color: #28a745;
  text-align: center;
  margin-bottom: 1px;
}
.pilotCardTitle {
  text-align: center;
  margin-bottom: 1px;
}
#pilotCardId {
  width: auto;
  background-color: transparent;
  border-radius: 12px;
  margin-right: 10px;
}
#busy-text {
  color: red;
  text-align: center;
  margin-bottom: 1px;
}
.redSymbol {
  color: red;
}
.greenSymbol {
  color: green;
}
#event-table {
  position: relative;
  top: 0vh;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

#greetings-card {
  position: relative;
  top: 2.5vh;
  color: #fff;
  height: 370px;
  max-width: 53%;
  top: 5vh;
  left: 40px;
  margin-bottom: 2vh;
  overflow-x: auto;
  overflow-y: auto;
  background-color: #212529;
  border: 5px solid #212529;
  border-radius: 12px;
  padding-top: 7px;
}
.weather {
  color: #fff;
}

.calendar-card {
  position: relative;
  height: 370px;
  width: 470px;
  font-size: 12px;
  top: 5vh;
  color: #fff;
  background-color: #212529;
  border: 5px solid #212529;
  border-radius: 12px;
  max-width: 800px;
  margin-bottom: 2vh;
  right: 40px;
  padding: 0px;
}
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 360px;
}
.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  width: 460px;
  font-size: 12px;
}
#requests {
  position: relative;
  top: 9vh;
}
.large {
  max-width: 100vw;
  width: 90vw;
}
#profileIcon {
  text-align: right;
  z-index: 3;
}
#logoutButton {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  height: 40px;
  position: relative;
}
#logoutButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#uploadBeforeAfterCheckButton {
  color: #fff;
  background-color: rgb(219, 45, 45);
  border-color: rgb(219, 45, 45);
}
#uploadBeforeAfterCheckButton:hover {
  background-color: rgb(104, 26, 26);
  border-color: rgb(104, 26, 26);
}

.allUsersList {
  max-height: 500px;
  overflow: auto;
  color: #fff;
  width: 100%;
  margin: auto;
}
#allUsersList td {
  font-weight: normal;
  max-height: 5px;
  padding: 5px;
  margin: auto;
  font-size: 14px;
  color: #fff;
}
#allUsersList th {
  padding: 5px;
  font-size: 14px;
  color: #fff;
}

#roleButton {
  font-size: 14px;
  color: #fff;
}
#deleteUser {
  font-size: 14px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-radius: 12px;
  height: 40px;
}
#deleteUser:hover {
  background-color: rgb(144, 55, 64);
  color: #fff;
  border-color: rgb(144, 55, 64);
}
#droneHistoryList {
  color: #fff;
  font-size: 14px;
  max-height: 500px;
  overflow: auto;
}
.requiredPilotsClass {
  background-color: #fff;
  color: #000;
  width: 250px;
}
.backGround {
  background-color: #6d6875;
  background-image: url("../assets/calendarPageBackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.backgroundColorCalendar {
  background-color: rgb(33, 37, 41, 0.3);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow-y: auto;
}
.fc .fc-daygrid-body {
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
}
/* ----------SCROLLBAR-----------*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6d6875;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f5c72;
}

.my-custom-scrollbar {
  position: relative;
  overflow: auto;
}
#sureButtonBatteryCreateCalendarPage {
  margin-right: 305px;
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#sureButtonBatteryCreateCalendarPage:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonBatteryCreateCalendarPage {
  margin-right: 0px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#cancelButtonBatteryCreateCalendarPage:hover {
  background-color: rgb(144, 55, 64);
}
#sureButtonUserEdit {
  margin-right: 230px;
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#sureButtonUserEdit:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonUserEdit {
  margin-right: 45px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#cancelButtonUserEdit:hover {
  background-color: rgb(144, 55, 64);
}

#sureButtonEventEdit {
  margin-right: 230px;
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#sureButtonEventEdit:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonEventEdit {
  margin-right: 45px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#cancelButtonEventEdit:hover {
  background-color: rgb(144, 55, 64);
}

#sureButtonCreateCalendarPage {
  color: #fff;
  min-width: 80px;
  position: relative;
  width: auto;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  margin-top: 1vh;
  margin-bottom: 1vh;
  right: 710px;
}
#sureButtonCreateCalendarPage:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonCreateDrone {
  right: 0px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  min-width: 80px;
  position: relative;
  width: auto;
  border-color: rgb(110, 40, 47);
  margin-top: 1vh;
  margin-bottom: 1vh;
}
#cancelButtonCreateDrone:hover {
  background-color: rgb(144, 55, 64);
}
#applyButtonCreateCalendarPage {
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#applyButtonCreateCalendarPage:hover {
  background-color: rgb(99, 116, 69);
}
.btn-danger {
  margin-right: 0px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
.btn-danger:hover {
  background-color: rgb(144, 55, 64);
}
.techDropdown {
  font-size: 16px;
  height: 40px;
  background-color: #fff;
  color: #4a4a4a;
  width: 54%;
  float: right;
  border-radius: 5px;
}

.requests {
  color: #000;
  position: relative;
  overflow-y: auto;
  height: 100%;
}

#listToggleButton {
  border-radius: 50px;
  background-color: #212529;
  color: white;
  border-color: #212529;
  position: absolute;
  top: 35px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#addNewDroneButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 235px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#userGuideButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 135px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#userGuideButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}
#basket-button {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 85px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#basket-button:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}
#addNewBatteryButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 285px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#addNewPilotButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 335px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#usersButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 385px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#usersButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#bugRepButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  bottom: 55px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#bugRepButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#logoutButton2 {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  bottom: 5px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#logoutButton2:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#userEditButton {
  color: white;
  border-color: #212529;
  position: absolute;
  background-color: #212529;
  border-radius: 50px;
  top: 185px;
  left: 20px;
  width: 50px;
  z-index: 5;
}
#userEditButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
.requests {
  color: black;
}

.new-drone-input {
  float: right;
  width: 54%;
}
.modal-content {
  top: -20px;
}
.new-battery-input {
  float: right;
  width: 54%;
}
.new-pilot-input {
  float: right;
  width: 54%;
}
.edit-drone {
  float: right;
}
.edit-battery {
  float: right;
}
.edit-pilot {
  float: right;
}
.new-user-input {
  float: right;
  width: 54%;
}
#acceptButtonRequestsModal {
  color: #fff;
  margin-right: 5px;
  position: relative;

  width: auto;
  right: 0px;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#acceptButtonRequestsModal:hover {
  background-color: rgb(99, 116, 69);
}
.btn-success {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
}
.btn-success:hover {
  background-color: rgb(99, 116, 69);
}
#acceptButton {
  color: #fff;
  margin-right: 0px;
  position: relative;
  float: right;
  width: auto;
  right: 0px;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
#acceptButton:hover {
  background-color: rgb(99, 116, 69);
}
#declineButton {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  position: relative;
  float: right;
  right: 0;
  width: auto;
}
#declineButton:hover {
  background-color: rgb(144, 55, 64);
}
#declineButtonRequestsModal {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#declineButtonRequestsModal:hover {
  background-color: rgb(144, 55, 64);
}
#cancelButtonRequestsModal {
  float: right;
}
.deleteListButton {
  color: #fff;
  background-color: rgb(110, 40, 47);
  width: auto;
  border-radius: 12px;
  font-size: 10px;
  margin-left: 2px;
  height: 30px;
}
.editListButton {
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-radius: 10px;
  font-size: 10px;
  height: 30px;
  width: auto;
}
#sureButtonPilotCreateCalendarPage {
  color: #fff;
  min-width: 80px;
  position: relative;
  width: auto;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  margin-top: 1vh;
  margin-bottom: 1vh;
  right: 275px;
}
#sureButtonPilotCreateCalendarPage:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonPilotCreateCalendarPage {
  right: 0px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  min-width: 80px;
  position: relative;
  width: auto;
  border-color: rgb(110, 40, 47);
  margin-top: 1vh;
  margin-bottom: 1vh;
}
#cancelButtonPilotCreateCalendarPage:hover {
  background-color: rgb(144, 55, 64);
}
.drone-line {
  display: block;
}

h2 {
  margin: 0;
  font-size: 16px;
}

/*---------------------------editlist ráviszed az egeret---------------------*/
.editListButton:hover {
  background-color: rgb(99, 116, 69);
  color: #fff;
  border-color: rgb(99, 116, 69);
}
/*---------------------------deleteist ráviszed az egeret---------------------*/
.deleteListButton:hover {
  background-color: rgb(144, 55, 64);
  color: #fff;
  border-color: rgb(144, 55, 64);
}

/*---------------------------listtogle ráviszed az egeret---------------------*/
#listToggleButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}

/*---------------------------Napokra ha ráviszed az egeret---------------------*/
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner:hover {
  background-color: #4f5c72;
}

/*---------------------------Apply button ráviszed az egeret---------------------*/
#apply-button:hover {
  background-color: rgb(99, 116, 69);
  border-color: rgb(99, 116, 69);
  color: #fff;
}

/*---------------------------newdrone ráviszed az egeret---------------------*/
button#addNewDroneButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}
/*---------------------------newpilot ráviszed az egeret---------------------*/
button#addNewPilotButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}

/*---------------------------new battery ráviszed az egeret---------------------*/
button#addNewBatteryButton:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}

/*---------------------------accept ráviszed az egeret---------------------*/
button#acceptButton:hover {
  background-color: rgb(99, 116, 69);
  border-color: rgb(99, 116, 69);
  color: #fff;
}
/*---------------------------decline ráviszed az egeret---------------------*/
button#declineButton:hover {
  background-color: rgb(144, 55, 64);
  border-color: rgb(144, 55, 64);
  color: #fff;
}

/*---------------------------naptár gomb szín, ráviszed az egeret---------------------*/
.fc .fc-button-primary {
  background-color: #4f5c72;
  border-color: #4f5c72;
  color: #fff;
}

.fc .fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*---------------------------Előre nyíl naptárban rákattnál---------------------*/
button.fc-next-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*---------------------------Hátra nyíl naptárban rákattnál---------------------*/
button.fc-prev-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*---------------------------Today alapszín ha aktív, azaz todayre mentél---------------------*/
.fc .fc-button-primary:disabled {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}
/*---------------------------Today rákattnál, ráviszed az egeret---------------------------*/
button.fc-today-button.fc-button.fc-button-primary:hover {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

/*--------------------------- Month week day-nél az aktív színe---------------------*/
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}
/*--------------------------- Week-nél a rákattintás színe---------------------*/
button.fc-timeGridWeek-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*--------------------------- Month nál a rákattintás színe---------------------*/
button.fc-dayGridMonth-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
/*--------------------------- Day-nél a rákattintás színe---------------------*/
button.fc-timeGridDay-button.fc-button.fc-button-primary:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
.fc-daygrid-day-bg:hover {
  color: #4a4a4a;
  background-color: #4a4a4a;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
@media (max-width: 1500px) and (min-width: 1160px) {
  .calendar-card {
    right: 30px;
    width: 370px;
    font-size: 10px;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    width: 350px;
    font-size: 9px;
  }
  #greetings-card {
    left: 40px;
    max-width: 50%;
    font-size: 12px;
    min-width: 420px;
  }
  #pilot-stats {
    max-width: 85%;
  }
  #my-events {
    max-width: 85%;
  }
  #my-events td {
    font-size: 12px;
  }
  #my-events th {
    font-size: 12px;
  }
  button#acceptButton {
    font-size: 12px;
  }
  button#declineButton {
    font-size: 12px;
  }
  #requests-table td {
    padding-right: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  #requests-table {
    margin-bottom: 0px;
  }
}
@media (max-width: 1160px) and (min-width: 940px) {
  .calendar-card {
    width: 370px;
    font-size: 10px;
    right: 0px;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    width: 360px;
    font-size: 10px;
  }
  #acceptButton {
    font-size: 12px;
    height: 30px;
  }
  #declineButton {
    font-size: 12px;
    height: 30px;
  }

  #pilot-card {
    font-size: 12px;
  }
  #my-events {
    font-size: 12px;
    max-width: 95%;
    left: 0px;
  }
  #my-events td {
    font-size: 12px;
  }
  #my-events th {
    font-size: 12px;
  }
  #pilot-stats {
    max-width: 95%;
    left: 0px;
  }

  #listToggleButton {
    top: 10px;
    left: 10px;
    z-index: 1;
  }
  #basket-button {
    top: 10px;
    left: 65px;
    z-index: 1;
  }
  #userGuideButton {
    top: 10px;
    left: 120px;
    z-index: 1;
  }
  #userEditButton {
    top: 10px;
    left: 175px;
    z-index: 1;
  }

  #addNewDroneButton {
    top: 10px;
    left: 230px;
    z-index: 1;
  }
  #addNewBatteryButton {
    top: 10px;
    left: 285px;
    z-index: 1;
  }
  #addNewPilotButton {
    top: 10px;
    left: 340px;
    z-index: 1;
  }

  #usersButton {
    top: 10px;
    left: 395px;
    z-index: 1;
  }
  #logoutButton2 {
    top: 10px;
    left: 94%;
    z-index: 1;
    bottom: auto;
  }
  #bugRepButton {
    top: 10px;
    left: 88%;
    z-index: 1;
    bottom: auto;
  }
  .containerCard {
    margin-top: 50px;
  }
  .modal {
    top: 10px;
  }
  #greetings-card {
    left: 0px;
    max-width: 50%;
    font-size: 12px;
    min-width: 420px;
  }
  #requests-table td {
    padding-right: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
  }
  #requests-table {
    margin-bottom: 0px;
  }
}
@media (max-width: 1400px) {
  .listButtonText {
    display: none;
  }
}
@media (max-width: 940px) and (min-width: 500px) {
  .containerCard {
    margin-top: 50px;
  }
  .calendar-card {
    width: 370px;
    font-size: 10px;
    left: 15px;
  }
  .modal-body {
    padding: 0px;
  }
  #showPasswordButton {
    position: absolute;
    left: 225px;
    bottom: 237px;
  }
  #showPasswordAgainButton {
    position: absolute;
    left: 225px;
    bottom: 200px;
  }
  input#editPhoneNumSelectInput {
    width: 37px;
    height: 30px;
  }
  input#phoneNumInput {
    width: 55px;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    width: 360px;
    font-size: 9px;
  }
  #acceptButton {
    font-size: 12px;
    height: 30px;
  }
  #declineButton {
    font-size: 12px;
    height: 30px;
  }

  #pilot-card {
    font-size: 12px;
  }
  #my-events {
    font-size: 12px;
    min-width: 450px;
    overflow-y: auto;
    overflow-x: auto;
  }
  #my-events td {
    font-size: 12px;
  }
  #my-events th {
    font-size: 12px;
  }
  h5 {
    font-size: 14px;
  }
  #pilot-stats {
    min-width: 450px;
  }
  #requests-table td {
    padding-right: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  #requests-table {
    margin-bottom: 0px;
    font-size: 12px;
  }
  #listToggleButton {
    top: 10px;
    left: 10px;
    z-index: 1;
  }
  #basket-button {
    top: 10px;
    left: 65px;
    z-index: 1;
  }
  #userGuideButton {
    top: 10px;
    left: 120px;
    z-index: 1;
  }
  #userEditButton {
    top: 10px;
    left: 175px;
    z-index: 1;
  }
  #addNewDroneButton {
    top: 10px;
    left: 230px;
    z-index: 1;
  }
  #addNewBatteryButton {
    top: 10px;
    left: 285px;
    z-index: 1;
  }
  #addNewPilotButton {
    top: 10px;
    left: 340px;
    z-index: 1;
  }

  #usersButton {
    top: 10px;
    left: 395px;
    z-index: 1;
  }
  #logoutButton2 {
    top: 10px;
    left: 89%;
    z-index: 1;
    bottom: auto;
  }
  #bugRepButton {
    top: 50px;
    left: 89%;
    z-index: 1;
    bottom: auto;
  }
  .modal {
    top: 10px;
  }
  #greetings-card {
    left: 30px;
    font-size: 12px;
    max-width: 400px;
  }
  #acceptButton {
    font-size: 10px;
  }
  #declineButton {
    font-size: 10px;
  }
  .allUsers td {
    font-size: 10px;
  }
  .allUsers th {
    font-size: 10px;
  }
  #deleteUser {
    font-size: 10px;
  }
  #roleButton {
    font-size: 10px;
  }
  .new-user-input {
    font-size: 10px;
  }
  #userEditModal {
    font-size: 10px;
  }
  #phoneTwo {
    max-width: 30px;
    height: 30px;
    padding: 7px;
  }
  #plusPhone {
    max-width: 35px;
    height: 30px;
    padding: 7px;
  }
  #sureButtonUserEdit {
    font-size: 10px;
    margin-right: 130px;
  }
  #cancelButtonUserEdit {
    font-size: 10px;
    margin-right: 30px;
  }

  #phoneNumInput {
    width: 60px;
    padding: 7px;
    height: 30px;
  }
  .new-drone-input {
    font-size: 10px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  #exampleModalCreate {
    font-size: 10px;
  }
  #sureButtonCreateCalendarPage {
    font-size: 10px;
    margin-right: 140px;
  }
  #cancelButtonCreateCalendarPage {
    font-size: 10px;
    margin-right: 20px;
  }
  .new-battery-input {
    font-size: 10px;
  }

  #exampleModalBatteryCreate {
    font-size: 10px;
  }
  #sureButtonBatteryCreateCalendarPage {
    font-size: 10px;
    margin-right: 140px;
  }
  #cancelButtonBatteryCreateCalendarPage {
    font-size: 10px;
    margin-right: 20px;
  }
  .new-pilot-input {
    font-size: 10px;
  }

  #exampleModalPilotCreate {
    font-size: 10px;
  }
  #sureButtonPilotCreateCalendarPage {
    font-size: 10px;
    margin-right: 60px;
  }
  #cancelButtonPilotCreateCalendarPage {
    font-size: 10px;
    margin-right: 40px;
  }
  #profileIcon {
    font-size: 8px;
  }
  #pIcon {
    font-size: 12px;
  }
  #uploadBeforeAfterCheckButton {
    font-size: 12px;
  }
  #first-row {
    padding-top: 20px;
  }
}

@media (max-width: 500px) {
  .containerCard {
    margin-top: 100px;
  }
  .requestsCardCol {
    padding-left: 0px;
    padding-right: 0px;
  }
  #showPasswordButton {
    position: absolute;
    left: 225px;
    bottom: 237px;
  }
  #showPasswordAgainButton {
    position: absolute;
    left: 225px;
    bottom: 200px;
  }
  input#editPhoneNumSelectInput {
    width: 37px;
    height: 30px;
  }
  input#phoneNumInput {
    width: 55px;
  }
  #eventModalEdit {
    left: 0px;
  }
  #eventModalDelete {
    left: 0px;
  }
  .beforeCheckTable {
    font-size: 12px;
  }
  #logFliesUploadAfter {
    font-size: 12px;
  }
  #imageUploadAfterDone {
    font-size: 12px;
  }
  #pdfUploadAfter {
    font-size: 12px;
  }
  #greetings-card {
    top: 6.5vh;
    left: 15px;
    min-width: 320px;
    margin: 0px;
    padding: 0px;
  }
  #requests-table td {
    padding-right: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  #requests-table {
    margin-bottom: 0px;
  }
  .requestsCard {
    font-size: 10px;
    margin: 0px;
    padding: 0px;
  }
  .calendar-card {
    width: 320px;
    font-size: 10px;
    top: 80px;
    left: 15px;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    width: 310px;
    font-size: 7px;
  }
  #uploadBeforeAfterCheckButton {
    font-size: 10px;
  }
  h5 {
    font-size: 14px;
  }
  #my-events {
    left: 0px;
    max-width: 320px;
    width: 320px;
    margin: auto;
    top: 100px;
    font-size: 10px;
    overflow-y: auto;
    overflow-x: auto;
  }
  #my-events td {
    font-size: 8px;
  }
  #my-events th {
    font-size: 8px;
  }
  #pilot-stats {
    left: 0px;
    max-width: 320px;
    margin: auto;
    top: 130px;
    font-size: 10px;
  }

  .containerCard {
    overflow-y: auto;
    border-radius: 0px;
    top: 40px;
    height: 650px;
    min-height: 650px;
    max-height: 650px;
    width: 100%;
  }

  #listToggleButton {
    top: 1px;
    left: 0px;
  }
  #basket-button {
    top: 1px;
    left: 55px;
  }
  #userGuideButton {
    top: 1px;
    left: 110px;
  }

  #addNewDroneButton {
    top: 1px;
    left: 165px;
  }
  #addNewBatteryButton {
    top: 1px;
    left: 220px;
  }
  #addNewPilotButton {
    top: 1px;
    left: 275px;
  }
  #userEditButton {
    top: 45px;
    left: 0px;
    z-index: 1;
  }
  #usersButton {
    top: 45px;
    left: 110px;
    z-index: 1;
  }
  #logoutButton2 {
    left: 55px;
    z-index: 1;
    top: 45px;
    bottom: auto;
  }
  #bugRepButton {
    left: 165px;
    z-index: 1;
    top: 45px;
    bottom: auto;
  }
  .modal {
    top: 10px;
  }
  .backGround {
    overflow: hidden;
  }
  #profileIcon {
    font-size: 8px;
  }
  #pIcon {
    font-size: 12px;
  }
  #acceptButton {
    font-size: 10px;
  }
  #declineButton {
    font-size: 10px;
  }
  .allUsers td {
    font-size: 10px;
  }
  .allUsers th {
    font-size: 10px;
  }
  #deleteUser {
    font-size: 10px;
  }
  #roleButton {
    font-size: 10px;
  }
  .new-user-input {
    font-size: 10px;
  }
  #userEditModal {
    font-size: 10px;
  }
  #phoneTwo {
    max-width: 30px;
    height: 30px;
    padding: 7px;
  }
  #plusPhone {
    max-width: 35px;
    height: 30px;
    padding: 7px;
  }
  #sureButtonUserEdit {
    font-size: 10px;
    margin-right: 130px;
  }
  #cancelButtonUserEdit {
    font-size: 10px;
    margin-right: 30px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  #phoneNumInput {
    width: 60px;
    padding: 7px;
    height: 30px;
  }
  .new-drone-input {
    font-size: 10px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  #exampleModalCreate {
    font-size: 10px;
  }
  #sureButtonCreateCalendarPage {
    font-size: 10px;
    margin-right: 140px;
  }
  #cancelButtonCreateCalendarPage {
    font-size: 10px;
    margin-right: 20px;
  }
  .new-battery-input {
    font-size: 10px;
  }

  #exampleModalBatteryCreate {
    font-size: 10px;
  }
  #sureButtonBatteryCreateCalendarPage {
    font-size: 10px;
    margin-right: 140px;
  }
  #cancelButtonBatteryCreateCalendarPage {
    font-size: 10px;
    margin-right: 20px;
  }
  .new-pilot-input {
    font-size: 10px;
  }

  #exampleModalPilotCreate {
    font-size: 10px;
  }
  #sureButtonPilotCreateCalendarPage {
    font-size: 10px;
    margin-right: 60px;
  }
  #cancelButtonPilotCreateCalendarPage {
    font-size: 10px;
    margin-right: 40px;
  }
  #event-table {
    max-width: 300px;
  }
}
</style>
